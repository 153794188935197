const APP_IMAGE_PATHS = {
  AVATAR: '/images/avatar.jpg',
  HAMBURGER: '/images/icon-hamburger.svg',
  LOGO: '/images/logo.svg',
  NOTIFICATION: '/images/notifications-bell.svg',
  LOGO_SIDEBAR: '/images/logo-sidebar.svg',
  ICON_DASHBOARD: '/images/icon-dashboard.svg',
  ICON_ACCOUNTS: '/images/icon-accounts.svg',
  ICON_SERVICES: '/images/icon-services.svg',
  ICON_EMPLOYEEE: '/images/icon-employee.svg',
  ICON_ESTABLISHMENT: '/images/icon-establishment-type.svg',
  ICON_INSPECTIONS: '/images/icon-inspections.svg',
  ICON_REPORTS: '/images/icon-reports.svg',
  ICON_TRACKING: '/images/icon-tracking.svg',
  LOCK_ICON: '/images/lock.jpg',
  EMAIL_ICON: '/images/email-icon.jpg',
  PASSWORD_EYE_ICON: '/images/icon-eye.svg',
  PASSWORD_EYE_OFF_ICON: '/images/icon-eye-off.svg',
  ICON_ESTABLISHMENT_TYPE: '/images/icon-establishment.svg',
  ICON_TABLE_EDIT: '/images/icon-table-edit.svg',
  ICON_PAGINATION_ARROW_BACK: '/images/ion-arrow-back.svg',
  ICON_SEARCH: '/images/icon-search.svg',
  ICON_FILTER: '/images/icon-filter.svg',
  ICON_SORT: '/images/icon-sort.svg',
  DELETE: '/images/icon_delete.svg',
  CONFIRM_DELETE: '/images/icon_delete_confirm.svg',
  LOGO_MINI: '/images/logo-minified.svg',
  VERIFY_ARROW_ICON: '/images/verify-arrow.svg',
  VERIFIED_TICK_ICON: '/images/icon-check-circle.svg',
  ARROW_DROPDOWN: '/images/arrow-dropdown.svg',
  DELETE_CONFIRM: '/images/icon_delete_confirm.svg',
  GENERATE_CERTIFICATE: '/images/icon-paper.svg',
  DOWN_ARROW: '/images/icon-down-arrow.svg',
  FILE_VIEW: '/images/view.svg',
  FILE_DELETE: '/images/delete.svg',
  FILE_DOWNLOAD: '/images/download.svg',
  EXCEL_UPLOAD:'/images/excel.svg',
  ICON_CLOSE:'/images/icon-close-circle.svg',
  ICON_LOCATION:'/images/location.svg',
  MAP_PIN:'/images/icon-map-pin.svg'
}

export default APP_IMAGE_PATHS;