import { createSlice } from '@reduxjs/toolkit'
import callAPI from 'utils/services'
import { getPdfPreviewUrl, notification } from 'utils/helper'
import API_ENDPOINT from 'constants/apiConstants'
import { Text } from 'languages'

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    list: [],
    totalCount: 0,
    reports: {},
    totalAmount: 0,
    reportsLoader: false,
    reportsDownloadLoader: false
  },
  reducers: {
    reportsListSuccess: (state, action) => {
      return {
        ...state,
        list: action?.payload?.rows || [],
        totalCount: action?.payload?.count || 0,
        totalAmount: action?.payload?.totalAmount || 0
      }
    },
    reportsListFailure: (state) => {
      return {
        ...state,
        list: []
      }
    },
    reportsLoaderToggle: (state, action) => {
      return {
        ...state,
        reportsLoader: action?.payload
      }
    },
    reportsDownloadLoaderToggle: (state, action) => {
      return {
        ...state,
        reportsDownloadLoader: action?.payload
      }
    }
  }
})

export const {
  reportsListSuccess,
  reportsListFailure,
  reportsLoaderToggle,
  reportsDownloadLoaderToggle
} = reportsSlice.actions

export function reportsGetListAction(payload, download) {
  const { reportType, ...rest }  = payload;
  return async (dispatch) => {
    const params = {
      pageNo: 1,
      pageSize: 10,
      ...rest
    }
    const apiObj = {
      url: reportType === 'service' ? API_ENDPOINT.V1.SERVICE_REPORT : API_ENDPOINT.V1.INSPECTION_REPORT,
      method: 'POST',
      data: params
    }

    if(download) {
      dispatch(reportsDownloadLoaderToggle(true))
      const response = await callAPI({
        ...apiObj,
        data: {
          ...params,
          download: true
        }
      });

      if(response.status === 201) {
        const url = response?.data?.path?.path || ''
        if(url) {
          notification({
            type: 'success',
            message: response?.message
          })
          window.open(getPdfPreviewUrl(url))
        } else {
          notification({
            type: 'error',
            message: <Text tid={"messages.filePathNotFound"}/>
          })
        }
      } else {
        notification({
          type: 'error',
          message: response?.message
        })
      }
      dispatch(reportsDownloadLoaderToggle(false))
    } else {
      dispatch(reportsLoaderToggle(true))
      const response = await callAPI(apiObj);

      if(response.status === 201) {
        dispatch(reportsListSuccess(response?.data || {}))
      } else {
        dispatch(reportsListFailure())
      }
      dispatch(reportsLoaderToggle(false))
    }
  }
}

export default reportsSlice.reducer
