import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const AuthRoute = ({ children }) => {
  const from = "/dashboard"; //location.state?.from?.pathname || '/dashboard'
  const { isLoggedIn } = useSelector((state) => state.authentication);
  if (isLoggedIn) {
    return <Navigate to={from} replace />;
  } else {
    return children;
  }
};

export default AuthRoute;
