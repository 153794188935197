import authenticationReducer from 'store/slices/authentication';
import sidebarReducer from 'store/slices/sidebar';
import dashboardReducer from 'store/slices/dashboard';
import employeeReducer from 'store/slices/employee';
import establishmentTypeReducer from 'store/slices/establishmentType';
import accountsReducer from 'store/slices/accounts';
import headerReducer from 'store/slices/header';
import servicesReducer from 'store/slices/services';
import reportsReducer from 'store/slices/reports';
import inspectionsReducer from 'store/slices/inspections';

const combinedReducer = () => ({
  dashboard: dashboardReducer,
  authentication: authenticationReducer,
  sidebar: sidebarReducer,
  establishmentType: establishmentTypeReducer,
  employee: employeeReducer,
  header: headerReducer,
  accounts: accountsReducer,
  services: servicesReducer,
  inspections: inspectionsReducer,
  reports: reportsReducer
})

export default combinedReducer;