import { createSlice } from '@reduxjs/toolkit'
import callAPI from 'utils/services'
import { history, notification } from 'utils/helper'
import API_ENDPOINT from 'constants/apiConstants'
import { inormativePoupOpenToggle } from "./header"
import { Text } from 'languages'
import GLOBAL_CONSTANTS from "constants"

const accountSentToggle = (dispatch, from = "", accountType = "") => {
  const { ACCOUNT_TYPE_VALUE = {} } = GLOBAL_CONSTANTS || {};
  const isUnderConstruction =
    accountType === ACCOUNT_TYPE_VALUE?.underConstruction;
  let descriptionTid = "usernamePasswordGenerated.usernameAndPasswordShared";
  if (isUnderConstruction) {
    descriptionTid = "usernamePasswordGenerated.underConstructionUsernameAndPasswordSharedMsg";
  }
  dispatch(
    inormativePoupOpenToggle({
      open: true,
      title: (
        <Text tid={"usernamePasswordGenerated.usernamePasswordGenerated"} />
      ),
      description: <Text tid={descriptionTid} />,
      redirectUrl: from === "LIST_PAGE" ? "" : "",
    })
  );
};

export const ACCOUNT_LOADER_TYPE = {
  LIST_API_CALL: 'LIST_API_CALL',
  DETAILS_API_CALL: 'DETAILS_API_CALL',
  ACCOUNT_RESEND_CALL: 'ACCOUNT_RESEND_CALL',
  UPDATE_API_CALL: 'UPDATE_API_CALL',
  CREATE_API_CALL: 'CREATE_API_CALL'
}

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    list: [],
    totalCount: 0,
    accounts: {},
    verifyPopup: false,
    accountsLoader: '',
    establishmentDetails: {},
    establishmentLoaderToggle: false
  },
  reducers: {
    accountsListSuccess: (state, action) => {
      return {
        ...state,
        list: action?.payload?.rows || [],
        totalCount: action?.payload?.count || 0
      }
    },
    accountsVeirySuccess: (state, action) => {
      return {
        ...state,
        ...action?.payload
      }
    },
    accountsListFailure: (state) => {
      return {
        ...state,
        list: []
      }
    },
    accountsLoaderToggle: (state, action) => {
      return {
        ...state,
        accountsLoader: action?.payload
      }
    },
    accountsVerifyToggle: (state, action) => {
      return {
        ...state,
        verifyPopup: action?.payload
      }
    },
    accountsDetailsSuccess: (state, action) => {
      return {
        ...state,
        accounts: action?.payload
      }
    },
    accountsDetailsFailure: (state) => {
      return {
        ...state,
        accounts: {}
      }
    },
    accountsListClear: (state) => {
      return {
        ...state,
        list: []
      }
    },
    establishmenLoaderToggle: (state, {payload}) => {
      state.establishmentLoaderToggle = payload;
    },
    establishmenDetailsSuccess: (state, {payload}) => {
      state.establishmentDetails = payload;
    },
    establishmenDetailsReset: (state) => {
      state.establishmentDetails = {};
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  accountsListSuccess,
  accountsListFailure,
  accountsDetailsSuccess,
  accountsDetailsFailure,
  accountsLoaderToggle,
  accountsVerifyToggle,
  accountsVeirySuccess,
  accountsListClear,
  establishmenLoaderToggle,
  establishmenDetailsSuccess,
  establishmenDetailsReset
} = accountsSlice.actions

export function accountsCreateAction(payload) {
  return async (dispatch) => {
    const params = {
      ...payload
    }
    dispatch(accountsLoaderToggle(ACCOUNT_LOADER_TYPE.CREATE_API_CALL))
    const response = await callAPI({
      url:API_ENDPOINT.V1.ACCOUNTS_CREATE,
      method: 'POST',
      data: params
    });

    if(response.status === 201) {
      if(response?.data?.smsSend) {
        dispatch(accountsVerifyToggle(true))
      }
      history.navigate('/accounts/'+(response?.data?.userId || 0)+'/view')
      notification({
        type: 'success',
        message: <Text tid={"messages.accountCreatedSuccessfully"}/> || response?.message
      })
    } else {
      notification({
        type: 'error',
        message: response?.message
      })
    }
    dispatch(accountsLoaderToggle(''))
  }
}

export function accountsUpdateAction({ id, account_id, data }) {
  return async (dispatch) => {
    dispatch(accountsLoaderToggle(ACCOUNT_LOADER_TYPE.UPDATE_API_CALL))
    const response = await callAPI({
      url: API_ENDPOINT.V1.ACCOUNTS+'/'+account_id,
      method: 'PUT',
      data
    });
    dispatch(accountsLoaderToggle(false))
    if(response.status === 201) {
      notification({
        type: 'success',
        message: <Text tid={"messages.accountUpdatedSuccessfully"}/> || response?.message
      })
      history.navigate('/accounts/'+id+'/view')
    } else {
      notification({
        type: 'error',
        message: response?.message
      })
    }
  }
}

export function accountsVerifyAction({from, data, accountType = ""}) {
  return async (dispatch, getState) => {
    dispatch(accountsLoaderToggle('ACCOUNT_VERIFY_CALL'))
    const response = await callAPI({
      url: API_ENDPOINT.V1.ACCOUNT_OTP_VERIFICATION,
      method: 'POST',
      data
    });
    dispatch(accountsLoaderToggle(''))
    if(response.status === 201) {
      dispatch(accountsVerifyToggle(false))
      notification({
        type: 'success',
        message: <Text tid={"messages.accountVerifiedSuccessfully"}/> || response?.message
      })
      if(response?.data?.verificationStatus) {
        accountSentToggle(dispatch, from, accountType)
      }
      if(from === 'DETAILS_PAGE') {
        const { accounts : { accounts } } = getState();
        dispatch(accountsVeirySuccess({
          accounts: {
            ...accounts,
            verificationStatus: true
          }
        }))
      }
      if(from === 'LIST_PAGE') {
        const { accounts : { list } } = getState();

        const newList = list.map((item) => {
          if(item?.userId === data.id) {
            return {
              ...item,
              verificationStatus: true
            }
          }
          return item
        })

        dispatch(accountsVeirySuccess({
          list: [
            ...newList
          ]
        }))
      }
    } else {
      notification({
        type: 'error',
        message: <Text tid={"messages.accountVerificationFailed"}/> || response?.message
      })
    }
  }
}

export function accountsPasswordResendAction(data) {
  return async (dispatch) => {
    dispatch(accountsLoaderToggle(ACCOUNT_LOADER_TYPE.ACCOUNT_RESEND_CALL))
    const response = await callAPI({
      url: API_ENDPOINT.V1.ACCOUNT_RESEND_PASSWORD,
      method: 'POST',
      data
    });
    dispatch(accountsLoaderToggle(''))
    if(response.status === 200) {
      notification({
        type: 'success',
        message: <Text tid={"messages.passwordResetSuccessfully"}/> || response?.message
      })
      accountSentToggle(dispatch)
    } else {
      notification({
        type: 'error',
        message: <Text tid={"messages.passwordResetfailed"}/> || response?.message
      })
    }
  }
}

export async function accountsresendOTPAction(data) {
  const response = await callAPI({
    url: API_ENDPOINT.V1.ACCOUNT_RESEND_OTP,
    method: 'POST',
    data
  });
  if(response.status === 200) {
    notification({
      type: 'success',
      message: <Text tid={"messages.oTPSentSuccessfully"}/> || response?.message
    })
    return true;
  } else {
    notification({
      type: 'error',
      message: <Text tid={"messages.OTPSentFailed"}/> || response?.message
    })
    return false;
  }
}

export function accountsDetailsAction(payload) {
  return async (dispatch) => {

    dispatch(accountsLoaderToggle(ACCOUNT_LOADER_TYPE.DETAILS_API_CALL))
    const response = await callAPI({
      url: API_ENDPOINT.V1.ACCOUNTS+'/'+payload,
      method: 'GET'
    });

    if(response.status === 200) {
      dispatch(accountsDetailsSuccess(response?.data || {}))
    } else {
      dispatch(accountsDetailsFailure())
    }
    dispatch(accountsLoaderToggle(''))
  }
}

export function accountsGetListAction(payload) {
  return async (dispatch) => {
    const params = {
      pageNo: 1,
      pageSize: 10,
      ...payload
    }
    dispatch(accountsLoaderToggle(ACCOUNT_LOADER_TYPE.LIST_API_CALL))
    const response = await callAPI({
      url:API_ENDPOINT.V1.ACCOUNTS_LIST,
      method: 'POST',
      data: params
    });

    if(response.status === 201) {
      dispatch(accountsListSuccess(response?.data || {}))
    } else {
      dispatch(accountsListFailure())
    }
    dispatch(accountsLoaderToggle(''))
  }
}

export function getEstablishmentDetails(serialId) {
  return async (dispatch) => {
    dispatch(establishmenLoaderToggle(true));
    const { status, data = {} } = await callAPI({
      url: `${API_ENDPOINT.V1.ESTABLISHMENT_DETAILS}/${serialId}`,
      method: "GET",
    });

    if (status === 200) {
      dispatch(establishmenDetailsSuccess(data || {}));
    } else {
      dispatch(establishmenDetailsReset(data || {}));
    }
    dispatch(establishmenLoaderToggle(false));
  };
}

export default accountsSlice.reducer
