import { getText } from "languages";
const ERROR_CONSTANTS = {
  USERNAME_REQUIRED: getText({ tid: "messages.pleaseEnterEmailIDUserName" }),
  USERNAME_NOT_VALID: "Please enter a valid Username",
  EMAIL_NOT_VALID: getText({ tid: "messages.pleaseEnterValidEmailID" }),
  EMAIL_REQUIRED: getText({ tid: "messages.pleaseEnterEmaiID" }),
  PASSWORD_REQUIRED: "Please enter Password",
  PASSWORD_NOT_VALID:
    "Password must contain atleast 1 upper case, numeric, special character, min 8 character and max 15 character",
  CONFIRM_PASSWORD: "The Password entered is not matching",
  LOGIN_API_ERROR:
    "You have entered an invalid Email ID or Password. Please try again with correct username and password",
  REQUIRED_FIELD: getText({ tid: "messages.pleaseEnterValue" }),
  REQUIRED_SELECT_FIELD: getText({ tid: "messages.pleaseSelectValue" }),
  OOPS_ERROR: "Oops. Something went wrong. Please try again later",
  FORGOT_PASSWORD_INFO:
    "A Verification link to change password has been sent to your Registered Email ID",
  MOBILE_NUMBER_NOT_VALID: getText({
    tid: "messages.pleaseEnterValid9DigitPhoneNumber",
  }),
  REQUIRED_MOBILE_NUMBER: getText({ tid: "messages.pleaseEnterPhoneNumber" }),
  INVALID_NUMBER: getText({ tid: "messages.PleaseEnterNumericalValue" }),
  INVALID_NUMBER_OR_DECIMAL:getText({ tid: "messages.PleaseEnterNumericalValueOrDecimal" }),
  CURRENT_PASSWORD: "Current password and the new password should not be same.",
  WRONG_PASSWORD: "Wrong current password",
};

export default ERROR_CONSTANTS;
