import appConfig from 'appConfig';

const API_ENDPOINT = {
  LOGIN: appConfig.API_BASE_URL + '/auth/login',
  FORGOT_PASSWORD: appConfig.API_BASE_URL + '/auth/forgetPassword',
  V1: {
    DASHBOARD: appConfig.API_BASE_URL + '/v1/dashboard',
    RESET_PASSWORD: appConfig.API_BASE_URL + '/v1/users/resetPassword',
    PROFILE: appConfig.API_BASE_URL + '/v1/users/profile',
    ESTABLISHMENT_TYPE: appConfig.API_BASE_URL + '/v1/establishment-type',
    EMPLOYEE: appConfig.API_BASE_URL + '/v1/employee',
    EMPLOYEE_LIST: appConfig.API_BASE_URL + '/v1/employee/list',
    EMPLOYEE_CREATE: appConfig.API_BASE_URL + '/v1/employee/create',
    CHANGE_PASSWORD: appConfig.API_BASE_URL + '/v1/users/changePassword',
    RESEND_CREDENTIAL: appConfig.API_BASE_URL + '/v1/employee/resend/credential',
    ACCOUNTS: appConfig.API_BASE_URL + '/v1/account',
    ACCOUNTS_LIST: appConfig.API_BASE_URL + '/v1/account/list',
    ACCOUNTS_CREATE: appConfig.API_BASE_URL + '/v1/account/create',
    ACCOUNT_OTP_VERIFICATION: appConfig.API_BASE_URL + '/v1/account/otp-verify',
    ACCOUNT_RESEND_PASSWORD: appConfig.API_BASE_URL + '/v1/account/resendPassword',
    ACCOUNT_RESEND_OTP: appConfig.API_BASE_URL + '/v1/account/resendOtp',
    SERVICE: appConfig.API_BASE_URL + '/v1/service',
    SERVICE_CREATE: appConfig.API_BASE_URL + '/v1/service/create',
    SERVICE_LIST: appConfig.API_BASE_URL + '/v1/service/list',
    SERVICE_STATUS: appConfig.API_BASE_URL + '/v1/service/status',
    SERVICE_SHEDULE: appConfig.API_BASE_URL + '/v1/service/schedule',
    SERVICE_DELETE: appConfig.API_BASE_URL + '/v1/service/delete',
    SERVICE_TRACKING: appConfig.API_BASE_URL + '/v1/service/tracking',
    FILE_UPLOAD: appConfig.API_BASE_URL + '/v1/file/upload',
    FILE_PREVIEW: appConfig.API_BASE_URL + '/preview',
    INSPECTION: appConfig.API_BASE_URL + '/v1/inspection',
    INSPECTION_LIST: appConfig.API_BASE_URL + '/v1/inspection/list',
    INSPECTION_STATUS: appConfig.API_BASE_URL + '/v1/inspection/status',
    INSPECTION_CREATE_SERVICE: appConfig.API_BASE_URL + '/v1/inspection/service/create',
    INSPECTION_SHEDULE: appConfig.API_BASE_URL + '/v1/inspection/schedule',
    INSPECTION_DELETE: appConfig.API_BASE_URL + '/v1/inspection/delete',
    INSPECTION_TRACKING: appConfig.API_BASE_URL + '/v1/inspection/tracking',
    INSPECTION_VISIT_RECORD: appConfig.API_BASE_URL + '/v1/inspection/record-visit',
    SERVICE_VISIT_RECORD: appConfig.API_BASE_URL + '/v1/service/record-visit',
    SEARCH_CONTRACTOR: appConfig.API_BASE_URL + '/v1/account/contractor/name-search',
    SERVICE_REPORT: appConfig.API_BASE_URL + '/v1/report/service',
    INSPECTION_REPORT: appConfig.API_BASE_URL + '/v1/report/inspection',
    ESTABLISHMENT_DETAILS: appConfig.API_BASE_URL + '/v1/establishment-details',
  }
}

export default API_ENDPOINT;
