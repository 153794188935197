import { createSlice } from '@reduxjs/toolkit'
import callAPI from 'utils/services'
import { history, notification } from 'utils/helper'
import API_ENDPOINT from 'constants/apiConstants'
import { inormativePoupOpenToggle } from "./header"
import { Text } from 'languages'

const employeeEmailSentToggle = (dispatch) => {
  dispatch(inormativePoupOpenToggle({
    open: true,
    title: <Text tid={"employee.usernamePasswordGenerated"}/>,
    description:  <Text tid={"employee.autogenerated"}/>,
    redirectUrl: '/employees'
  }))
}

export const employeeSlice = createSlice({
  name: 'employee',
  initialState: {
    list: [],
    totalCount: 0,
    employee: {},
    employeeLoader: false,
    emailSentLoader: false
  },
  reducers: {
    employeeListSuccess: (state, action) => {
      return {
        ...state,
        list: action?.payload?.rows || [],
        totalCount: action?.payload?.count || 0
      }
    },
    employeeListFailure: (state) => {
      return {
        ...state,
        list: []
      }
    },
    employeeLoaderToggle: (state, action) => {
      return {
        ...state,
        employeeLoader: action?.payload
      }
    },
    emailSentLoaderToggle: (state, action) => {
      return {
        ...state,
        emailSentLoader: action?.payload
      }
    },
    employeeDetailsSuccess: (state, action) => {
      return {
        ...state,
        employee: action?.payload
      }
    },
    employeeDetailsFailure: (state) => {
      return {
        ...state,
        employee: {}
      }
    },
    employeeCreate: (state) => {
      return {
        ...state,
        employee: {},
      }
    },
    employeeUpdateState: (state, action) => {
      return {
        ...state,
        employee: {
          ...state.employee,
          ...action?.payload
        }
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  employeeCreate,
  employeeListSuccess,
  employeeListFailure,
  employeeDetailsSuccess,
  employeeLoaderToggle,
  employeeUpdateState,
  emailSentLoaderToggle
} = employeeSlice.actions

export function employeeCreateAction(payload) {
  return async (dispatch) => {
    const params = {
      name: payload.name,
      nameArb: payload.nameArb,
      email: payload.email,
      mobileNumber: payload.mobileNumber,
      designation: payload.designation,
      employeeId: payload.employeeId,
      role: Number(payload.role),
      loginAccess: payload.loginAccess
    }
    dispatch(employeeLoaderToggle(true))
    const response = await callAPI({
      url:API_ENDPOINT.V1.EMPLOYEE_CREATE,
      method: 'POST',
      data: params
    });

    if(response.status === 201) {
      dispatch(employeeCreate())
      notification({
        type: 'success',
        message: response?.message || <Text tid={"messages.employeeCreatedSuccessfully"}/>
      })
      if(response?.data?.emailSend) {
        employeeEmailSentToggle(dispatch);
      } else {
        history.navigate('/employees')
      }
    } else {
      notification({
        type: 'error',
        message: response?.message
      })
    }
    dispatch(employeeLoaderToggle(false))
  }
}

export function employeeUpdateAction({ id, params }) {
  return async (dispatch) => {
    const data = {
      name: params.name,
      nameArb: params.nameArb,
      email: params.email,
      mobileNumber: params.mobileNumber,
      designation: params.designation,
      employeeId: params.employeeId,
      role:params.role,
      loginAccess: params.loginAccess,
      status: params.status
    }
    dispatch(employeeLoaderToggle(true))
    const response = await callAPI({
      url: API_ENDPOINT.V1.EMPLOYEE+'/'+id,
      method: 'PUT',
      data
    });
    dispatch(employeeLoaderToggle(false))
    if(response.status === 200) {
      notification({
        type: 'success',
        message: response?.message || <Text tid={"messages.employeeUpdatedSuccessfully"}/>
      })
      if(response?.data?.emailSend) {
        employeeEmailSentToggle(dispatch);
      } else {
        history.navigate('/employees/'+id+'/view')
      }
    } else {
      notification({
        type: 'error',
        message: response?.message
      })
    }
  }
}

export function employeeDetailsAction(payload) {
  return async (dispatch) => {

    dispatch(employeeLoaderToggle(true))
    const response = await callAPI({
      url: API_ENDPOINT.V1.EMPLOYEE+'/'+payload,
      method: 'GET'
    });

    if(response.status === 200) {
      dispatch(employeeDetailsSuccess(response?.data || {}))
    } else {
      dispatch(employeeDetailsFailure())
    }
    dispatch(employeeLoaderToggle(false))
  }
}

export function employeeGetListAction(payload) {
  return async (dispatch) => {
    const params = {
      name: "",
      nameArb: "",
      email: "",
      mobileNumber: "",
      employeeId: "",
      roles: [],
      pageNo: 1,
      pageSize: 10,
      ...payload
    }
    dispatch(employeeLoaderToggle(true))
    const response = await callAPI({
      url:API_ENDPOINT.V1.EMPLOYEE_LIST,
      method: 'POST',
      data: params
    });

    if(response.status === 200) {
      dispatch(employeeListSuccess(response?.data || {}))
    } else {
      dispatch(employeeListFailure())
    }
    dispatch(employeeLoaderToggle(false))
  }
}

export function employeeResendCredentialAction(employeeId) {
  return async (dispatch) => {
    const params = {
      employeeId
    }
    dispatch(emailSentLoaderToggle(true))
    const response = await callAPI({
      url:API_ENDPOINT.V1.RESEND_CREDENTIAL,
      method: 'POST',
      data: params
    });

    if(response.status === 200) {
      dispatch(employeeUpdateState({
        credentialSend: true
      }))
      notification({
        type: 'success',
        message: response?.message
      })
      employeeEmailSentToggle(dispatch);
    } else {
      notification({
        type: 'error',
        message: response?.message
      })
    }
    dispatch(emailSentLoaderToggle(false))
  }
}

export default employeeSlice.reducer