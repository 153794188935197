import { createSlice } from '@reduxjs/toolkit'
import callAPI from 'utils/services'
import API_ENDPOINT from 'constants/apiConstants'
import GLOBAL_CONSTANTS from 'constants';
import ERROR_CONSTANTS from "constants/errorConstants";
import {
  getUserToken,
  storeUserDetails,
  setUserDetails,
  userLanguage,
  clearUserDetails,
} from 'utils/helper';

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    logoutPopup: false,
    loginLoader: false,
    isLoggedIn: Boolean(getUserToken()) || false,
    userProfile: null,
    loginError: null,
    language: userLanguage.get() || GLOBAL_CONSTANTS.LANGUAGES[0].value
  },
  reducers: {
    loginSuccess: (state) => {
      return {
        ...state,
        isLoggedIn: true,
        loginError: null
      }
    },
    profileSuccess: (state, action) => {
      return {
        ...state,
        loginLoader: false,
        userProfile: action.payload
      }
    },
    toggleLogoutPopup: (state, action) => {
      return {
        ...state,
        logoutPopup: action.payload
      }
    },
    toggleLoginLoader: (state, action) => {
      return {
        ...state,
        loginLoader: action.payload
      }
    },
    logOut: state => {
      return {
        ...state,
        userProfile: null,
        loginError: null,
        isLoggedIn: false
      }
    },
    setLanguage: (state, action) => {
      return {
        ...state,
        language: action.payload
      }
    },
    loginFailed: (state, action) => {
      return {
        ...state,
        userProfile: null,
        loginError: action.payload,
        isLoggedIn: false
      }
    },
    clearApiError: state => {
      return {
        ...state,
        loginError: null
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  loginSuccess,
  logOut,
  toggleLogoutPopup,
  toggleLoginLoader,
  loginFailed,
  setLanguage,
  profileSuccess,
  clearApiError
} = authenticationSlice.actions

export function loginAction(payload) {
  return async (dispatch) => {
    dispatch(toggleLoginLoader(true))
    const params = {
      email: payload.email,
      password: payload.password
    }
    const response = await callAPI({
      url: API_ENDPOINT.LOGIN,
      method: 'POST',
      data: params
    });

    if(response.status === 201) {
      storeUserDetails(response.data);
      dispatch(loginSuccess())
    } else {
      let message = response.message;
      if(message === 'INVALID_CREDENTIALS') {
        message = ERROR_CONSTANTS.LOGIN_API_ERROR;
      }
      const error = {
        errorCode: response.errorCode,
        message
      }
      dispatch(loginFailed(error))
    }

    dispatch(toggleLoginLoader(false))
  }
}

export function profileAction() {
  return async (dispatch) => {
    dispatch(toggleLoginLoader(true))
    const response = await callAPI({
      url:  API_ENDPOINT.V1.PROFILE,
      method: 'GET'
    });

    if(response.status === 201) {
      const userData = setUserDetails(response.data);
      dispatch(profileSuccess(userData))
    } else {
      let message = response.message;
      if(message === 'INVALID_CREDENTIALS') {
        message = ERROR_CONSTANTS.LOGIN_API_ERROR;
      }
      clearUserDetails()
      const error = {
        errorCode: response.errorCode,
        message
      }
      dispatch(loginFailed(error))
    }
    dispatch(toggleLoginLoader(false))
  }
}

export default authenticationSlice.reducer
