import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 400;
        overflow-y: scroll;
    }

    p {
        font-size: 14px;
        color: #211F1E;
        &.bold {
            font-weight: 500;
        }
        @media (max-width: 767.98px) {
            font-size: 12px;
        }
    }

    .logout-card-logo {
        img {
            max-width: 100%;
            width: 157px;
        }
    }

    .logout-card-message {
        color: #003057;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        margin: 0;
    }

    .dialogue-card {
        border: 1px solid #00000014;
        box-shadow: 0px 2px 10px #00000014;
        border-radius: 10px;
    }

    .place-content-center {
        justify-content: center !important;
        display: flex;
        width: 100%;
    }

    .place-content-middle {
        justify-content: center !important;
        align-items: center !important;
        display: flex !important;
    }

    .place-content-v-middle {
        align-items: center !important;
        display: flex !important;
    }

    .logout-popup-card {
        width: 100%;
        max-width: 590px;
        .card-body {
            padding: 40px;
        }
        .logout-popup-footer {
            padding-top: 40px;
        }
    }

    .dashboard-popup {
        & > .modal-dialog {
            max-width: 100%;
            .modal-content {
                align-items: center;
                background: transparent;
                border: 0;
            }
        }
    }

    .form-input-group {
        .visibility-icon {
            position: absolute;
            z-index: 5;
            top: 0;
            right: 0;
            cursor: pointer;
            display: flex;
            width: 25px;
            height: 100%;
            > img {
                max-width: 15px;
            }
        }
    }

    .message {
        font-size: 12px;
        line-height: 18px;
        font-weight: 300;
        margin-bottom: 4px;
        &.danger {
            color: #E41919;
        }
        &.sucess {
            color: #28a745;
        }
    }

    .form-label {
        color: #000000;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 5px;
        &.important {
            &:after {
                content: "*";
                color: #F40303;
                margin-left: 4px;
            }
        }
    }

    .page-title{
        color: #003057;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        margin: 0;
        @media (max-width: 767.98px) {
            font-size: 14px;
        }
    }

    .side-header {
        color: #000000;
        font-weight: 300;
        font-size: 14px;
    }

    .content-page-header {
        padding: 0 15px;
        align-items: center;
        min-height: 75px;
        display: flex;
        border-bottom: 1px solid #EBEDEF;
        background-color: #fff;
        .page-title {
            display: contents;
            min-height: 40px;
        }
        @media (max-width: 767.98px) {
            min-height: 50px;
            padding: 6px 15px;
            .page-title {
                min-height: 36px;
            }
        }
        .header-buttons {
            display: flex;
            justify-content: flex-end;
            > div {
                display: flex;
                align-items: center;
            }
        }
    }
    .content-page-body {
        padding: 30px 15px 0px;
        &.with-table {
            padding-top: 0px;
            padding-left: 0;
            padding-right: 0;
        }
        &.no-paTB {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }

    .content-page-footer {
        padding: 10px 15px;
        min-height: 60px;
        align-items: center;
        display: flex;
        border-top: 1px solid #EBEDEF;
        background: #ffffff;
        &.grey-footer {
            background: #F3F4F5;
        }
        .content-page-buttons {
            display: flex;
            justify-content: flex-end;
            > div {
                display: flex;
                align-items: center;
            }
        }
        &.fixed-footer {
            position: fixed;
            bottom: 0;
            right: 0;
            left: 0;
            html:not([dir=rtl]) .sidebar:not(.sidebar-end) ~ .wrapper & {
                @media (min-width: 768px) {
                    left: 255px;
                }
            }
            html:not([dir=rtl]) .sidebar.hide:not(.sidebar-end) ~ .wrapper & {
                @media (min-width: 768px) {
                    left: 90px;
                }
            }
            *[dir=rtl] .sidebar:not(.sidebar-end) ~ .wrapper & {
                @media (min-width: 768px) {
                    right: 255px;
                }
            }
            *[dir=rtl] .sidebar.hide:not(.sidebar-end) ~ .wrapper & {
                @media (min-width: 768px) {
                    right: 90px;
                }
            }
        }
    }

    .bg-light {
        background-color: #F3F4F5 !important;
    }

    .profile-enable {
        margin-left: 15px;
        margin-right: 0px;
        html:not([dir="rtl"]) & {
            margin-right: 15px;
            margin-left: 0px;
        }
        .form-check-input:disabled,
        .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
            cursor: default;
            opacity: 1;
        }
    }

    .fixed-loader {
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: 53;
        left: 0;
        top: 0;
        align-items: center;
    }

    .t-badge {
        line-height: 20px;
        padding: 0 15px;
        min-width: 74px;
        border-radius: 2px !important;
        font-size: 9px;
        font-weight: 400;
        color: #ffffff;
        &.bg-success {
            background-color: #00A44D !important;
        }
        &.bg-grey {
            background-color: #949494 !important;
        }
    }

    .account-verify-link {
        font-weight: 400 !important;
        font-size: 12px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        img {
            margin-left: 5px;
        }
    }

    .inline-header{
        color: #0095A9;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 14px;
    }
    .inline-sub-header{
        color: #000000;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 12px;
    }
    .inspection-header{
        color: #003057;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 14px;
    }
    .service-delete-btn {
        width: auto !important;
        min-width: auto !important;
        background: transparent !important;
        padding: 0 !important;
        border-radius: 100% !important;
    }
    .pac-container {
        z-index: 1060;
    }
    /* #TODO: report css move later: akarsh */
    .totalamount {
        cursor: pointer;
        color: #003057;
        font-weight: 600;
        font-size: 12px;
        border-radius: 0;
        border: 1px solid #F3F4F5;
        min-width: 125px;
        // text-align: center;
        // position: absolute;
        right: 27px;
        display: block;
        padding: 8px 10px;
        text-align: end;
        background: rgb(255 255 255 / 87%);
    }
    *[dir="rtl"] .form-select {
        direction: ltr;
        text-align: right;
    }
    input[type="text"]:disabled {
      background:#f2f2f2;
    }
`;

export default GlobalStyle;
