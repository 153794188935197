import React, { Suspense } from 'react'
import { NotificationContainer } from 'react-notifications';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import Loader from 'components/Loader'
import AuthRoute from 'layouts/AuthRoute'
import { history } from 'utils/helper'
import 'react-notifications/lib/notifications.css';

// Containers
const DefaultLayout = React.lazy(() => import('layouts/DefaultLayout'))
import PrivateRoute from 'layouts/PrivateRoute'

// Pages
const LoginPage = React.lazy(() => import('pages/Login'))
const ForgotPasswordPage = React.lazy(() => import('pages/ForgotPassword'))
const ResetPasswordPage = React.lazy(() => import('pages/ResetPassword'))
const PdfViewer = React.lazy(() => import('pages/PdfViewer'))

const App = () => {
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate()
  history.location = useLocation()

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <NotificationContainer />
      <Suspense fallback={Loader()}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<AuthRoute><LoginPage /></AuthRoute>} />
          <Route exact path="/forgot-password" name="Forgot Password Page" element={<AuthRoute><ForgotPasswordPage /></AuthRoute>} />
          <Route exact path="/reset-password/:token" name="Reset Password Page" element={<AuthRoute><ResetPasswordPage /></AuthRoute>} />
          <Route exact path='/preview' name="preview page" element={<PdfViewer />} />
          <Route path="*" name="Home" element={<PrivateRoute><DefaultLayout /></PrivateRoute>} />
        </Routes>
      </Suspense>
    </div>
  )
}

export default App
