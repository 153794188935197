import Axios from 'axios';
import {
  getUserToken
} from 'utils/helper';
import ERROR_CONSTANTS from "constants/errorConstants";

const callAPI = ({ headers, ...args }) => {
  const axiosParams = {
    headers: {
      Authorization: getUserToken(),
      ...headers
    },
    ...args
  }
  return Axios(axiosParams)
    .then((res) => {
      const data =  res?.data?.data?.responseParameter || {};
      const message = data?.message || res?.data?.message || '';
      const status = res?.data?.status || 201;
      return {
        data,
        message,
        status
      }
    })
    .catch((err) => {
      const errorCode = err?.response?.data?.error?.code || 500;
      let message = err?.response?.data?.error?.message || ERROR_CONSTANTS.OOPS_ERROR;
      const status = err?.response?.data?.status || 500;
      if(err?.response?.status === 500) {
        message = ERROR_CONSTANTS.OOPS_ERROR
      }
      return {
        errorCode,
        message,
        status
      }
    })
}

export default callAPI;