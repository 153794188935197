import { createSlice } from '@reduxjs/toolkit'
import callAPI from 'utils/services'
import { history, notification } from 'utils/helper'
import API_ENDPOINT from 'constants/apiConstants'
import { Text } from 'languages'

export const establishmentTypeSlice = createSlice({
  name: 'establishmentType',
  initialState: {
    list: [],
    totalCount: 0,
    establishmentType: {},
    establishmentTypeLoader: false,
    estTypeDelLoder: ''
  },
  reducers: {
    establishmentTypeListSuccess: (state, action) => {
      return {
        ...state,
        list: action?.payload?.rows || [],
        totalCount: action?.payload?.count || 0
      }
    },
    establishmentTypeListFailure: (state) => {
      return {
        ...state,
        list: []
      }
    },
    establishmentTypeLoaderToggle: (state, action) => {
      return {
        ...state,
        establishmentTypeLoader: action?.payload
      }
    },
    establishmentTypeDetailsSuccess: (state, action) => {
      return {
        ...state,
        establishmentType: action?.payload
      }
    },
    establishmentTypeDetailsFailure: (state) => {
      return {
        ...state,
        establishmentType: {}
      }
    },
    establishmentTypeCreate: (state) => {
      return {
        ...state,
        establishmentType: {},
      }
    },
    estTypeDelLoaderToggle: (state, action) => {
      return {
        ...state,
        estTypeDelLoder: action.payload
      }
    },
  }
})

// Action creators are generated for each case reducer function
export const {
  establishmentTypeCreate,
  establishmentTypeListSuccess,
  establishmentTypeListFailure,
  establishmentTypeDetailsSuccess,
  establishmentTypeDetailsFailure,
  establishmentTypeLoaderToggle,
  estTypeDelLoaderToggle
} = establishmentTypeSlice.actions

export function establishmentTypeCreateAction(payload) {
  return async (dispatch) => {
    const params = {
      name: payload.name,
      nameArb: payload.nameArb,
      visitInYear: payload.visitInYear
    }
    dispatch(establishmentTypeLoaderToggle(true))
    const response = await callAPI({
      url:API_ENDPOINT.V1.ESTABLISHMENT_TYPE,
      method: 'POST',
      data: params
    });

    if(response.status === 201) {
      dispatch(establishmentTypeCreate())
      notification({
        type: 'success',
        message: response?.message || <Text tid={"messages.establishmentCreatedSuccessfully"}/>
      })
      history.navigate('/establishment-type')
    } else {
      notification({
        type: 'error',
        message: response?.message
      })
    }
    dispatch(establishmentTypeLoaderToggle(false))
  }
}

export function establishmentTypeUpdateAction({ id, params }) {
  return async (dispatch) => {
    const data = {
      name: params.name,
      nameArb: params.nameArb,
      visitInYear: params.visitInYear
    }
    dispatch(establishmentTypeLoaderToggle(true))
    const response = await callAPI({
      url: API_ENDPOINT.V1.ESTABLISHMENT_TYPE+'/'+id,
      method: 'PUT',
      data
    });
    dispatch(establishmentTypeLoaderToggle(false))
    if(response.status === 200) {
      notification({
        type: 'success',
        message: response?.message || <Text tid={"messages.establishmentUpdatedSuccessfully"}/>
      })
      history.navigate('/establishment-type/'+id+'/view')
    } else {
      notification({
        type: 'error',
        message: response?.message
      })
    }
  }
}

export function establishmentTypeDetailsAction(payload) {
  return async (dispatch) => {

    dispatch(establishmentTypeLoaderToggle(true))
    const response = await callAPI({
      url: API_ENDPOINT.V1.ESTABLISHMENT_TYPE+'/'+payload,
      method: 'GET'
    });

    if(response.status === 200) {
      dispatch(establishmentTypeDetailsSuccess(response?.data || {}))
    } else {
      dispatch(establishmentTypeDetailsFailure())
    }
    dispatch(establishmentTypeLoaderToggle(false))
  }
}

export function establishmentTypeGetListAction(payload) {
  return async (dispatch) => {
    const params = {
      name: "",
      nameArb: "",
      visitInYear: "",
      pageNo: 1,
      pageSize: 10,
      ...payload
    }
    dispatch(establishmentTypeLoaderToggle(true))
    const response = await callAPI({
      url:API_ENDPOINT.V1.ESTABLISHMENT_TYPE,
      method: 'GET',
      params
    });

    if(response.status === 200) {
      dispatch(establishmentTypeListSuccess(response?.data || {}))
    } else {
      dispatch(establishmentTypeListFailure())
    }
    dispatch(establishmentTypeLoaderToggle(false))
  }
}


export function establishmentTypeDeleteAction(payload) {
  return async (dispatch) => {
    dispatch(estTypeDelLoaderToggle("LOADING"));
    const response = await callAPI({
      url: API_ENDPOINT.V1.ESTABLISHMENT_TYPE + "/" + payload,
      method: "DELETE",
    });

    let message = response?.message || <Text tid={"messages.pleaseTryLater"} />;
    let type = "error";
    if (response.status === 200) {
      type = "success";
      dispatch(estTypeDelLoaderToggle("SUCCESS"));
    } else if (response.status === 409) {
      type = "warning";
      dispatch(estTypeDelLoaderToggle(""));
    } else {
      type = "error";
      dispatch(estTypeDelLoaderToggle(""));
    }
    notification({
      type,
      message,
    });
  };
}


export default establishmentTypeSlice.reducer