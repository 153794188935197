import APP_IMAGE_PATHS from "constants/imagePaths";
import arb from "../languages/arb.json";
import eng from "../languages/eng.json";

export const ACCOUNT_TYPE_VALUE = {
  underConstruction: "Under Construction",
  existingEstablishment: "Existing Establishment",
};

export const ACCOUNT_SUB_TYPE = {
  commercial: "Commercial",
  nonCommercial: "Non Commercial"
}

export const ACCOUNT_SUB_TYPE_TWO = {
  business: "Business",
  residential: "Residential",
  public: "Public",
  private: "Private",
}

export const EMIRATES_VALUES = {
  RAK: "RAK",
  UAQ: "UAQ",
  FJH: "FJH",
  AJM: "AJM",
  SHJ: "SHJ",
  DXB: "DXB",
  AUD: "AUD"
}

export const EMIRATES_LIST = [
  { label: eng.emirates.RAK, value: EMIRATES_VALUES.RAK },
  { label: eng.emirates.UAQ, value: EMIRATES_VALUES.UAQ },
  { label: eng.emirates.FJH, value: EMIRATES_VALUES.FJH },
  { label: eng.emirates.AJM, value: EMIRATES_VALUES.AJM },
  { label: eng.emirates.SHJ, value: EMIRATES_VALUES.SHJ },
  { label: eng.emirates.DXB, value: EMIRATES_VALUES.DXB },
  { label: eng.emirates.AUD, value: EMIRATES_VALUES.AUD },
]

export const EMIRATES_LIST_ARB = [
  { label: arb.emirates.RAK, value: EMIRATES_VALUES.RAK },
  { label: arb.emirates.UAQ, value: EMIRATES_VALUES.UAQ },
  { label: arb.emirates.FJH, value: EMIRATES_VALUES.FJH },
  { label: arb.emirates.AJM, value: EMIRATES_VALUES.AJM },
  { label: arb.emirates.SHJ, value: EMIRATES_VALUES.SHJ },
  { label: arb.emirates.DXB, value: EMIRATES_VALUES.DXB },
  { label: arb.emirates.AUD, value: EMIRATES_VALUES.AUD },
]

const SIDEMENU_TYPE = {
  dasboard: "Dashboard",
  account: "Account",
  services: "Services",
  serviceTracking: "service Tracking",
  inspection: "Inspection",
  inspectionTracking: "Inspection Tracking",
  establishmentType: "Establishment Type",
  employees: "Employees",
  reports: "Reports",
};

const SIDEMENU = [
  {
    name: "hamburgerMenu.dashboard",
    to: "/dashboard",
    icon: APP_IMAGE_PATHS.ICON_DASHBOARD,
    type: SIDEMENU_TYPE.dasboard,
  },
  {
    name: "hamburgerMenu.account",
    to: "/accounts",
    icon: APP_IMAGE_PATHS.ICON_ACCOUNTS,
    type: SIDEMENU_TYPE.account,
  },
  {
    name: "hamburgerMenu.services",
    to: "/services",
    icon: APP_IMAGE_PATHS.ICON_SERVICES,
    type: SIDEMENU_TYPE.services,
  },
  {
    name: "hamburgerMenu.serviceTracking",
    to: "/services-tracking",
    icon: APP_IMAGE_PATHS.ICON_TRACKING,
    type: SIDEMENU_TYPE.serviceTracking,
  },
  {
    name: "hamburgerMenu.inspection",
    to: "/inspections",
    icon: APP_IMAGE_PATHS.ICON_INSPECTIONS,
    type: SIDEMENU_TYPE.inspection,
  },
  {
    name: "hamburgerMenu.inspectiontracking",
    to: "/inspections-tracking",
    icon: APP_IMAGE_PATHS.ICON_TRACKING,
    type: SIDEMENU_TYPE.inspectionTracking,
  },
  {
    name: "hamburgerMenu.establishmentType",
    to: "/establishment-type",
    icon: APP_IMAGE_PATHS.ICON_ESTABLISHMENT,
    type: SIDEMENU_TYPE.establishmentType,
  },
  {
    name: "hamburgerMenu.employee",
    to: "/employees",
    icon: APP_IMAGE_PATHS.ICON_EMPLOYEEE,
    type: SIDEMENU_TYPE.employees,
  },
  {
    name: "hamburgerMenu.reports",
    to: "/reports",
    icon: APP_IMAGE_PATHS.ICON_REPORTS,
    type: SIDEMENU_TYPE.reports,
  },
]

const STAGE_OF_CONSTRUCTION = {
  beforePccAndFoundation: 1,
  afterTheTieBeam: 2,
  outerWallFromInside: 3,
  beforeInterlockingWork: 4,
};

export const USER_ROLES = {
  ADMIN: 1,
  MANGER: 2,
  CSD: 3,
  KIOSK_EXECUTIVE: 4,
  FIELD_ENGINEER: 5,
  GOVT_EMPLOYEES: 6,
  OTHERS: 7,
  CUSTOMER:8
};




const USER_ROLE_SIDEMENU = {
  [USER_ROLES.ADMIN]: [
    SIDEMENU_TYPE.dasboard,
    SIDEMENU_TYPE.account,
    SIDEMENU_TYPE.services,
    SIDEMENU_TYPE.serviceTracking,
    SIDEMENU_TYPE.inspection,
    SIDEMENU_TYPE.inspectionTracking,
    SIDEMENU_TYPE.establishmentType,
    SIDEMENU_TYPE.employees,
    SIDEMENU_TYPE.reports,
  ],
  [USER_ROLES.MANGER]: [
    SIDEMENU_TYPE.dasboard,
    SIDEMENU_TYPE.account,
    SIDEMENU_TYPE.services,
    SIDEMENU_TYPE.serviceTracking,
    SIDEMENU_TYPE.inspection,
    SIDEMENU_TYPE.inspectionTracking
  ],
  [USER_ROLES.CSD]: [
    SIDEMENU_TYPE.dasboard,
    SIDEMENU_TYPE.account,
    SIDEMENU_TYPE.services,
    SIDEMENU_TYPE.serviceTracking,
    SIDEMENU_TYPE.inspection,
    SIDEMENU_TYPE.inspectionTracking
  ],
  [USER_ROLES.KIOSK_EXECUTIVE]: [
    SIDEMENU_TYPE.dasboard,
    SIDEMENU_TYPE.account,
    SIDEMENU_TYPE.services,
    SIDEMENU_TYPE.serviceTracking,
    SIDEMENU_TYPE.inspection,
    SIDEMENU_TYPE.inspectionTracking,
  ],
  [USER_ROLES.FIELD_ENGINEER]: [],
  [USER_ROLES.GOVT_EMPLOYEES]: [
    SIDEMENU_TYPE.serviceTracking,
    SIDEMENU_TYPE.inspectionTracking
  ],
  [USER_ROLES.OTHERS]: [
    SIDEMENU_TYPE.dasboard,
    SIDEMENU_TYPE.account,
    SIDEMENU_TYPE.services,
    SIDEMENU_TYPE.serviceTracking,
    SIDEMENU_TYPE.inspection,
    SIDEMENU_TYPE.inspectionTracking
  ],
  [USER_ROLES.CUSTOMER]: [
    SIDEMENU_TYPE.services,
    SIDEMENU_TYPE.serviceTracking,
    SIDEMENU_TYPE.inspectionTracking
  ]
};

const STATUS = {
  PENDING: 1,
  UPCOMING: 2,
  ACTIVE: 3,
  REJECTED: 4,
  COMPLETED: 5,
  RESCHEDULED: 6,
};

const SERVICES_STATUS = {
  PENDING_FOR_APPROVAL: 1,
  SCHEDULED: 2,
  APPROVED: 3,
  REJECTED: 4,
  RESCHEDULE_REQUESTED: 5,
  HEADING_TO_LOCATION: 6,
  REACHED_LOCATION: 7,
  SERVICES_STARTED: 8,
  SERVICES_SUCCESSFUL: 9,
  REPORT_GENERATED: 10,
  PENDING: 11,
  INSPECTION_STARTED: 12,
  INSPECTION_SUCCESSFUL: 13,
  INSPECTION_FAILED: 14,
  COMPLETED: 15,
  AGREEMENT_GENERATED: 16,
  SUSPENDED: 17
};

const INSPECTION_STATUS = {
  PENDING_FOR_APPROVAL: 1,
  SCHEDULED: 2,
  APPROVED: 3,
  REJECTED: 4,
  RESCHEDULE_REQUESTED: 5,
  HEADING_TO_LOCATION: 6,
  REACHED_LOCATION: 7,
  INSPECTION_STARTED: 8,
  INSPECTION_SUCCESSFUL: 9,
  REPORT_GENERATED: 10,
  PENDING: 11,
  SUSPENDED: 12
};

const GLOBAL_CONSTANTS = {
  DATE_FORMAT: {
    USER_DROPDOWN: "EEE dd MMM yyyy hh:mm a",
    PROFILE_PAGE: "dd/MM/yyyy hh:mm a",
  },
  LANGUAGES: [
    { label: "English", value: "english" },
    { label: arb.language.arabic, value: "arabic" },
  ],
  MENU: SIDEMENU,
  USER_ROLE_SIDEMENU: USER_ROLE_SIDEMENU,
  USER_ROLES: USER_ROLES,
  ROLES: [
    {
      value: USER_ROLES.ADMIN,
      label: eng.selectConstants.admin,
    },
    {
      value: USER_ROLES.MANGER,
      label: eng.selectConstants.Manager,
    },
    {
      value: USER_ROLES.CSD,
      label: eng.selectConstants.csd,
    },
    {
      value: USER_ROLES.KIOSK_EXECUTIVE,
      label: eng.selectConstants.kioskExecutive,
    },
    {
      value: USER_ROLES.FIELD_ENGINEER,
      label: eng.selectConstants.fieldEngineer,
    },
    {
      value: USER_ROLES.GOVT_EMPLOYEES,
      label: eng.selectConstants.govermentEmployee,
    },
    {
      value: USER_ROLES.OTHERS,
      label: eng.selectConstants.others,
    },
  ],
  ROLES_ARB: [
    {
      value: USER_ROLES.ADMIN,
      label: arb.selectConstants.admin,
    },
    {
      value: USER_ROLES.MANGER,
      label: arb.selectConstants.Manager,
    },
    {
      value: USER_ROLES.CSD,
      label: arb.selectConstants.csd,
    },
    {
      value: USER_ROLES.KIOSK_EXECUTIVE,
      label: arb.selectConstants.kioskExecutive,
    },
    {
      value: USER_ROLES.FIELD_ENGINEER,
      label: arb.selectConstants.fieldEngineer,
    },
    {
      value: USER_ROLES.GOVT_EMPLOYEES,
      label: arb.selectConstants.govermentEmployee,
    },
    {
      value: USER_ROLES.OTHERS,
      label: arb.selectConstants.others,
    },
  ],
  LOGIN_ACCESS: [
    {
      label: "Yes",
      value: "1",
    },
    {
      label: "No",
      value: "0",
    },
  ],
  LOGIN_ACCESS_ARB: [
    {
      label: arb.employee.yes,
      value: "1",
    },
    {
      label: arb.employee.no,
      value: "0",
    },
  ],
  ACCOUNT_TYPE_VALUE: ACCOUNT_TYPE_VALUE,
  ACCOUNT_TYPE: [
    {
      label: "Existing Establishment",
      value: ACCOUNT_TYPE_VALUE.existingEstablishment,
    },
    {
      label: "Under Construction",
      value: ACCOUNT_TYPE_VALUE.underConstruction,
    },
  ],
  ACCOUNT_TYPE_ARB: [
    {
      label: arb.selectConstants.existingEstablishment,
      value: ACCOUNT_TYPE_VALUE.existingEstablishment,
    },
    {
      label: arb.selectConstants.underConstruction,
      value: ACCOUNT_TYPE_VALUE.underConstruction,
    },
  ],
  ACCOUNT_SUB_TYPE: [
    {
      label: eng.selectConstants.commercial,
      value: ACCOUNT_SUB_TYPE.commercial,
    },
    {
      label: eng.selectConstants.noncommercial,
      value: ACCOUNT_SUB_TYPE.nonCommercial,
    },
  ],
  ACCOUNT_SUB_TYPE_ARB: [
    {
      label: arb.selectConstants.commercial,
      value:  ACCOUNT_SUB_TYPE.commercial,
    },
    {
      label: arb.selectConstants.noncommercial,
      value: ACCOUNT_SUB_TYPE.nonCommercial,
    },
  ],
  COMMERCIAL_SUB_TYPE: [
    {
      label: eng.selectConstants.business,
      value: ACCOUNT_SUB_TYPE_TWO.business,
    },
    {
      label: eng.selectConstants.residential,
      value: ACCOUNT_SUB_TYPE_TWO.residential,
    },
  ],
  COMMERCIAL_SUB_TYPE_ARB: [
    {
      label: arb.selectConstants.business,
      value: ACCOUNT_SUB_TYPE_TWO.business,
    },
    {
      label: arb.selectConstants.residential,
      value: ACCOUNT_SUB_TYPE_TWO.residential,
    },
  ],
  NON_COMMERCIAL_SUB_TYPE: [
    {
      label: eng.selectConstants.public,
      value: ACCOUNT_SUB_TYPE_TWO.public,
    },
    {
      label: eng.selectConstants.private,
      value: ACCOUNT_SUB_TYPE_TWO.private,
    },
  ],
  NON_COMMERCIAL_SUB_TYPE_ARB: [
    {
      label: arb.selectConstants.public,
      value: ACCOUNT_SUB_TYPE_TWO.public,
    },
    {
      label: arb.selectConstants.private,
      value: ACCOUNT_SUB_TYPE_TWO.private,
    },
  ],
  COMMERCIAL_SUB_TYPE_RESIDENTIAL: [
    {
      label: "Flat",
      value: "Flat",
    },
    {
      label: "Appartment",
      value: "Appartment",
    },
  ],
  COMMERCIAL_SUB_TYPE_RESIDENTIAL_ARB: [
    {
      label: arb.selectConstants.flat,
      value: "Flat",
    },
    {
      label: arb.selectConstants.appartment,
      value: "Appartment",
    },
  ],
  REJECTED_SERVICES_STATUS: [
    {
      value: 1,
      label: "Incomplete Documents",
    },
    {
      value: 2,
      label: "Agreement not Clear",
    },
    {
      value: 3,
      label: "Others",
    },
  ],
  REJECTED_SERVICES_STATUS_ARB: [
    {
      value: 1,
      label: arb.selectConstants.incompleteDocuments,
    },
    {
      value: 2,
      label: arb.selectConstants.agreementNotClear,
    },
    {
      value: 3,
      label: arb.selectConstants.others,
    },
  ],
  REJECTED_SERVICES_STATUS_MAP: {
    1: "Incomplete Documents",
    2: "Agreement not Clear",
    3: "Others",
  },
  STAGE_OF_CONSTRUCTION_TYPE: STAGE_OF_CONSTRUCTION,
  STAGE_OF_CONSTRUCTION: [
    {
      label: "stageOfConstruction.beforePccAndFoundation",
      value: STAGE_OF_CONSTRUCTION.beforePccAndFoundation,
    },
    {
      label: "stageOfConstruction.afterTheTieBeam",
      value: STAGE_OF_CONSTRUCTION.afterTheTieBeam,
    },
    {
      label: "stageOfConstruction.outerWallFromInside",
      value: STAGE_OF_CONSTRUCTION.outerWallFromInside,
    },
    {
      label: "stageOfConstruction.beforeInterlockingWork",
      value: STAGE_OF_CONSTRUCTION.beforeInterlockingWork,
    },
  ],
  SERVICE_TYPE_STATUS: STATUS,
  INSPECTION_TYPE_STATUS: STATUS,
  INSPECTION_STATUS: INSPECTION_STATUS,
  SERVICES_STATUS: SERVICES_STATUS,
  STATUS_LIST: [
    {
      label: "Pending for Approval",
      value: INSPECTION_STATUS.PENDING_FOR_APPROVAL,
    },
    { label: "Scheduled", value: INSPECTION_STATUS.SCHEDULED },
    { label: "Approved", value: INSPECTION_STATUS.APPROVED },
    { label: "Rejected", value: INSPECTION_STATUS.REJECTED },
    {
      label: "Reschedule Requested",
      value: INSPECTION_STATUS.RESCHEDULE_REQUESTED,
    },
    {
      label: "Heading to Location",
      value: INSPECTION_STATUS.HEADING_TO_LOCATION,
    },
    { label: "Reached Location", value: INSPECTION_STATUS.REACHED_LOCATION },
    {
      label: "Inspection Started",
      value: INSPECTION_STATUS.INSPECTION_STARTED,
    },
    {
      label: "Inspection Successful",
      value: INSPECTION_STATUS.INSPECTION_SUCCESSFUL,
    },
    { label: "Report Generated", value: INSPECTION_STATUS.REPORT_GENERATED },
    { label: "Pending", value: INSPECTION_STATUS.PENDING },
    { label: "Suspended", value: INSPECTION_STATUS.SUSPENDED },
  ],
  STATUS_LIST_ARB: [
    {
      label: arb.selectConstants.pendingForApproval,
      value: INSPECTION_STATUS.PENDING_FOR_APPROVAL,
    },
    {
      label: arb.selectConstants.scheduled,
      value: INSPECTION_STATUS.SCHEDULED,
    },
    { label: arb.selectConstants.approved, value: INSPECTION_STATUS.APPROVED },
    { label: arb.selectConstants.rejected, value: INSPECTION_STATUS.REJECTED },
    {
      label: arb.selectConstants.rescheduledRequested,
      value: INSPECTION_STATUS.RESCHEDULE_REQUESTED,
    },
    {
      label: arb.selectConstants.headingToLocation,
      value: INSPECTION_STATUS.HEADING_TO_LOCATION,
    },
    {
      label: arb.selectConstants.reachedLocation,
      value: INSPECTION_STATUS.REACHED_LOCATION,
    },
    {
      label: arb.selectConstants.inspectionStarted,
      value: INSPECTION_STATUS.INSPECTION_STARTED,
    },
    {
      label: arb.selectConstants.inspectionSuccessfull,
      value: INSPECTION_STATUS.INSPECTION_SUCCESSFUL,
    },
    {
      label: arb.selectConstants.reportGenerated,
      value: INSPECTION_STATUS.REPORT_GENERATED,
    },
    { label: arb.selectConstants.pending, value: INSPECTION_STATUS.PENDING },
    { label: arb.selectConstants.suspended, value: INSPECTION_STATUS.SUSPENDED },
  ],
  SERVICE_STATUS_LIST: [
    {
      label: "Agreement Generated",
      value: SERVICES_STATUS.AGREEMENT_GENERATED,
    },
    {
      label: "Pending for Approval",
      value: SERVICES_STATUS.PENDING_FOR_APPROVAL,
    },
    { label: "Scheduled", value: SERVICES_STATUS.SCHEDULED },
    { label: "Approved", value: SERVICES_STATUS.APPROVED },
    { label: "Rejected", value: SERVICES_STATUS.REJECTED },
    {
      label: "Reschedule Requested",
      value: SERVICES_STATUS.RESCHEDULE_REQUESTED,
    },
    {
      label: "Heading to Location",
      value: SERVICES_STATUS.HEADING_TO_LOCATION,
    },
    { label: "Reached Location", value: SERVICES_STATUS.REACHED_LOCATION },
    { label: "Service Started", value: SERVICES_STATUS.SERVICES_STARTED },
    { label: "Service Successful", value: SERVICES_STATUS.SERVICES_SUCCESSFUL },
    { label: "Report Generated", value: SERVICES_STATUS.REPORT_GENERATED },
    { label: "Pending", value: SERVICES_STATUS.PENDING },
    { label: "Inspection Started", value: SERVICES_STATUS.INSPECTION_STARTED },
    {
      label: "Inspection Successful",
      value: SERVICES_STATUS.INSPECTION_SUCCESSFUL,
    },
    { label: "Inspection Failed", value: SERVICES_STATUS.INSPECTION_FAILED },
    { label: "Completed", value: SERVICES_STATUS.COMPLETED },
    { label: "Suspended", value: SERVICES_STATUS.SUSPENDED },
  ],
  SERVICE_STATUS_LIST_ARB: [
    {
      label: arb.selectConstants.agreementGenerated,
      value: SERVICES_STATUS.AGREEMENT_GENERATED,
    },
    {
      label: arb.selectConstants.pendingForApproval,
      value: SERVICES_STATUS.PENDING_FOR_APPROVAL,
    },
    { label: arb.selectConstants.scheduled, value: SERVICES_STATUS.SCHEDULED },
    { label: arb.selectConstants.approved, value: SERVICES_STATUS.APPROVED },
    { label: arb.selectConstants.rejected, value: SERVICES_STATUS.REJECTED },
    {
      label: arb.selectConstants.rescheduledRequested,
      value: SERVICES_STATUS.RESCHEDULE_REQUESTED,
    },
    {
      label: arb.selectConstants.headingToLocation,
      value: SERVICES_STATUS.REACHED_LOCATION,
    },
    {
      label: arb.selectConstants.reachedLocation,
      value: SERVICES_STATUS.REACHED_LOCATION,
    },
    {
      label: arb.trackingStatus.serviceStarted,
      value: SERVICES_STATUS.SERVICES_STARTED,
    },
    {
      label: arb.trackingStatus.serviceSuccessfull,
      value: SERVICES_STATUS.SERVICES_SUCCESSFUL,
    },
    {
      label: arb.selectConstants.reportGenerated,
      value: SERVICES_STATUS.REPORT_GENERATED,
    },
    { label: arb.selectConstants.pending, value: SERVICES_STATUS.PENDING },
    {
      label: arb.selectConstants.inspectionStarted,
      value: SERVICES_STATUS.INSPECTION_STARTED,
    },
    {
      label: arb.selectConstants.inspectionSuccessfull,
      value: SERVICES_STATUS.INSPECTION_SUCCESSFUL,
    },
    {
      label: arb.selectConstants.inspectionFailed,
      value: SERVICES_STATUS.INSPECTION_FAILED,
    },
    { label: arb.selectConstants.completed, value: SERVICES_STATUS.COMPLETED },
    { label: arb.selectConstants.suspended, value: SERVICES_STATUS.SUSPENDED },
  ],

  INSPECTION_STATUS_SELECT_LIST: [
    { label: "Required", value: "1" },
    { label: "Not Required", value: "0" },
  ],
  INSPECTION_STATUS_SELECT_LIST_ARB: [
    { label: arb.selectConstants.required, value: "1" },
    { label: arb.selectConstants.notRequired, value: "0" },
  ],
  STATUS_ENABLE_DISABLE: [
    { label: "Enabled", value: 1 },
    { label: "Disabled", value: 0 },
  ],
  STATUS_ENABLE_DISABLE_ARB: [
    { label: arb.selectConstants.enabled, value: 1 },
    { label: arb.selectConstants.disabled, value: 0 },
  ],
  STATUS_VERIFY_NOT: [
    { label: "Verified", value: 1 },
    { label: "Not Verified", value: 0 },
  ],
  STATUS_VERIFY_NOT_ARB: [
    { label: arb.selectConstants.verified, value: 1 },
    { label: arb.selectConstants.notVerified, value: 0 },
  ],
  VISIT_REQUIRED_IN_AN_YEAR_COUNT: [...new Array(12)].map((each, index) => ({
    label: index + 1,
    value: index + 1,
  })),
};

export default GLOBAL_CONSTANTS;
