import { NotificationManager } from 'react-notifications';
import { format } from 'date-fns'
import axios from 'axios'
import GLOBAL_CONSTANTS from 'constants';
import { Text ,Translate} from "languages"
import API_ENDPOINT from "constants/apiConstants";
import { ACCOUNT_TYPE_VALUE } from 'constants';
import { ACCOUNT_SUB_TYPE } from 'constants';
import { ACCOUNT_SUB_TYPE_TWO } from 'constants';
import { USER_ROLES } from 'constants';
import { EMIRATES_LIST } from 'constants';
import { EMIRATES_LIST_ARB } from 'constants';

export const storeUserDetails = (user) => {
  localStorage.setItem('access_token', user.token);
}

export const getUserToken = () => {
  return localStorage.getItem('access_token');
}

export const clearUserDetails = () => {
  localStorage.removeItem('access_token');
}

export const setUserDetails = (user) => {
  return {
    image: user?.Image || '',
    id: user?.id || '',
    userId: user?.userId || '',
    name: user?.name || '',
    nameArb: user?.nameArb || '',
    role: user?.role || '',
    modules: user?.modules || '',
    type: user?.type || '',
    email: user?.email || '',
    mobileNumber: user?.mobileNumber || '',
    lastLogin: user?.lastLogin || '',
    uploadUrl: user?.uploadUrl
  }
}

export const userLanguage = {
  set: function (lan) {
    localStorage.setItem('language', lan);
  },
  get: function () {
    return localStorage.getItem('language');
  },
  remove: function () {
    localStorage.removeItem('language');
  }
}

export const getPdfPreviewUrl = (fileName,download=false) => {
  return API_ENDPOINT.V1.FILE_PREVIEW+`?path=${fileName}&download=${download}`
}

export const history = {
  navigate: null,
  location: null
}

export const setDirectionInHTML = (lang = '') => {
  if (lang === GLOBAL_CONSTANTS.LANGUAGES[1].value) {
    userLanguage.set(lang);
    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  } else {
    userLanguage.remove(lang);
    document.getElementsByTagName("html")[0].removeAttribute("dir");
  }
}

export const notification = ({ type, message }) => {
  switch (type) {
    case 'info':
      NotificationManager.info(message);
      break;
    case 'success':
      NotificationManager.success(message);
      break;
    case 'warning':
      NotificationManager.warning(message);
      break;
    case 'error':
      NotificationManager.error(message);
      break;
  }
}

export const displayEmploeeRole = (value) => {
  const role = GLOBAL_CONSTANTS.ROLES.filter((item) => item.value === Number(value));
  return role.length ? role[0].label : ''
}

export const contentFooterfix = () => {
  const contentFooter = document.querySelector(".content-page-footer.fixed-footer");
  const contentBody = document.querySelector(".default-layout-body");
  if (contentFooter) {
    const heightFooter = contentFooter.offsetHeight
    if (contentBody) {
      contentBody.style.paddingBottom = heightFooter + 'px';
      if (!contentFooter.classList.contains('grey-footer')) {
        contentBody.classList.add('bg-light');
      } else {
        contentBody.classList.remove('bg-light');
      }
    }
  } else {
    if (contentBody) {
      contentBody.style.paddingBottom = '0px';
      contentBody.classList.remove('bg-light');
    }
  }
}

window.contentFooterfix = () => {
  setTimeout(() => {
    contentFooterfix();
  }, 1000)
};

export const formatSortQuery = (sortObj) => {
  for (let key in sortObj) {
    return sortObj[key] ? [key, sortObj[key]] : undefined
  }
}

export const formatDate = (date, FORMAT = 'MM/dd/yyyy') => {
  try {
    return date ? format(new Date(date), FORMAT) : '';
  } catch(err) {
    console.log(err)
    return '';
  }
}

export const formatDateISO = (date) => {
  const time = formatDate(new Date(), "HH:mm:ss")
  let dateToday = new Date(new Date(date).setHours(0,0,0,0)).toString();
  dateToday = dateToday.replace(/00:00:00/g, time)
  console.log(dateToday);
  return date ? new Date(dateToday).toISOString() : '';
}

export const formatTimeISO = (time) => {
  let dateToday = new Date(new Date().setHours(0,0,0,0)).toString();
  dateToday = dateToday.replace(/00:00:00/g, time+":00")
  return time ? new Date(dateToday).toISOString() : '';
}

export const getStartEndDate = (input) => {
  if (!input) {
    return {}
  }
  const {
    from = null,
    to = null
  } = input || {}
  const fromDate = from ? formatDateISO(
    new Date(
      Number(from?.year),
      Number(from?.month)-1,
      Number(from?.day)
    )
  ) : null;

  const toDate = to ? formatDateISO(
    new Date(
      Number(to?.year),
      Number(to?.month)-1,
      Number(to?.day)
    )
  ) : null;
  return { fromDate, toDate } ;
}

export const getDatepickerDateObj = (date) => {
  if (date) {
    const someDate = new Date(date);
    const day = someDate.getDate();
    const month = someDate.getMonth()+1;
    const year = someDate.getFullYear();
    return {
      day: !isNaN(day) ? day : null,
      month: !isNaN(month) ? month : null,
      year: !isNaN(year) ? year : null,
    };
  }
  return null;
};


export const getInspectionStatus = (status = "") => {
  const { INSPECTION_STATUS } = GLOBAL_CONSTANTS || {};
  switch (status) {
    case INSPECTION_STATUS.PENDING_FOR_APPROVAL:
      return {
        label: <Text tid={"trackingStatus.pendingForApproval"} />,
        color: "red",
      };
    case INSPECTION_STATUS.SCHEDULED:
      return {
        label: <Text tid={"trackingStatus.scheduled"} />,
        color: "green",
      };
    case INSPECTION_STATUS.APPROVED:
      return {
        label: <Text tid={"trackingStatus.approved"} />,
        color: "green",
      };
    case INSPECTION_STATUS.REJECTED:
      return {
        label: <Text tid={"trackingStatus.rejected"} />,
        color: "red",
      };
    case INSPECTION_STATUS.RESCHEDULE_REQUESTED:
      return {
        label: <Text tid={"trackingStatus.rescheduledRequested"} />,
        color: "green",
      };
    case INSPECTION_STATUS.HEADING_TO_LOCATION:
      return {
        label: <Text tid={"trackingStatus.headingToLocation"} />,
        color: "yellow",
      };
    case INSPECTION_STATUS.REACHED_LOCATION:
      return {
        label: <Text tid={"trackingStatus.reachedLocation"} />,
        color: "green",
      };
    case INSPECTION_STATUS.INSPECTION_STARTED:
      return {
        label: <Text tid={"trackingStatus.inspectionStarted"} />,
        color: "yellow",
      };
    case INSPECTION_STATUS.INSPECTION_SUCCESSFUL:
      return {
        label: <Text tid={"trackingStatus.inspectionSuccessfull"} />,
        color: "green",
      };
    case INSPECTION_STATUS.REPORT_GENERATED:
      return {
        label: <Text tid={"trackingStatus.reportGenerated"} />,
        color: "green",
      };
    case INSPECTION_STATUS.PENDING:
      return {
        label: <Text tid={"trackingStatus.pending"} />,
        color: "green",
      };
    case INSPECTION_STATUS.SUSPENDED:
      return {
        label: <Text tid={"trackingStatus.suspended"} />,
        color: "red",
      };
    default:
      return {};
  }
};

export const getServiceStatus = (status = "") => {
  const { SERVICES_STATUS } = GLOBAL_CONSTANTS || {};
  switch (status) {
    case SERVICES_STATUS.AGREEMENT_GENERATED:
      return {
        label: <Text tid={"trackingStatus.agreementGenerated"} />,
        color: "green",
      };
    case SERVICES_STATUS.PENDING_FOR_APPROVAL:
      return {
        label: <Text tid={"trackingStatus.pendingForApproval"} />,
        color: "red",
      };
    case SERVICES_STATUS.SCHEDULED:
      return {
        label: <Text tid={"trackingStatus.scheduled"} />,
        color: "green",
      };
    case SERVICES_STATUS.APPROVED:
      return {
        label: <Text tid={"trackingStatus.approved"} />,
        color: "green",
      };
    case SERVICES_STATUS.REJECTED:
      return {
        label: <Text tid={"trackingStatus.rejected"} />,
        color: "red",
      };
    case SERVICES_STATUS.RESCHEDULE_REQUESTED:
      return {
        label: <Text tid={"trackingStatus.rescheduledRequested"} />,
        color: "green",
      };
    case SERVICES_STATUS.HEADING_TO_LOCATION:
      return {
        label: <Text tid={"trackingStatus.headingToLocation"} />,
        color: "yellow",
      };
    case SERVICES_STATUS.REACHED_LOCATION:
      return {
        label: <Text tid={"trackingStatus.reachedLocation"} />,
        color: "green",
      };
    case SERVICES_STATUS.SERVICES_STARTED:
      return {
        label: <Text tid={"trackingStatus.serviceStarted"} />,
        color: "yellow",
      };
    case SERVICES_STATUS.SERVICES_SUCCESSFUL:
      return {
        label: <Text tid={"trackingStatus.serviceSuccessfull"} />,
        color: "green",
      };
    case SERVICES_STATUS.REPORT_GENERATED:
      return {
        label: <Text tid={"trackingStatus.reportGenerated"} />,
        color: "green",
      };
    case SERVICES_STATUS.PENDING:
      return {
        label: <Text tid={"trackingStatus.pending"} />,
        color: "yellow",
      };
    case SERVICES_STATUS.INSPECTION_STARTED:
      return {
        label: <Text tid={"trackingStatus.inspectionStarted"} />,
        color: "yellow",
      };
    case SERVICES_STATUS.INSPECTION_SUCCESSFUL:
      return {
        label: <Text tid={"trackingStatus.inspectionSuccessfull"} />,
        color: "green",
      };
    case SERVICES_STATUS.INSPECTION_FAILED:
      return {
        label: <Text tid={"trackingStatus.inspectionFailed"} />,
        color: "red",
      };
    case SERVICES_STATUS.COMPLETED:
      return {
        label: <Text tid={"trackingStatus.completed"} />,
        color: "green",
      };
    case SERVICES_STATUS.SUSPENDED:
      return {
        label: <Text tid={"trackingStatus.suspended"} />,
        color: "red",
      };
    default:
      return {};
  }
};

export const formatEstablishmentOptionList = (list) => {
  return list.map((item) => ({
    label:Translate( item?.name, item?.nameArb),
    value: item?.id
  }));
}

export const formatAccountOptionList = (list) => {
  return list.filter((item) => item?.status).map((item) => ({
    label: item?.accountUniqueId,
    value: item?.userId
  }));
}

export const formatEmployeeOptionList = (list, roleId = '') => {
  return list.filter((item) => Number(item.role) === Number(roleId)).map((item) => ({
    label: Translate( item?.name, item?.nameArb),
    value: item?.id
  }));
}
export const formatEmployeeOptionListEn = (list, roleId = '') => {
  return list.filter((item) => Number(item.role) === Number(roleId)).map((item) => ({
    label:item?.name,
    value: item?.name
  }));
}
export const formatEmployeeOptionListArb = (list, roleId = '') => {
  return list.filter((item) => Number(item.role) === Number(roleId)).map((item) => ({
    label: item?.nameArb,
    value: item?.nameArb
  }));
}

export const getPredefinedDocumentList = (accounts = {}, isInspection = false, isServiceCompleted = false) => {
  const {
    accountType,
    subTypeTwo
  } = accounts;
  let result = [];
  let madatoryList = [];
  switch (accountType) {
    case GLOBAL_CONSTANTS.ACCOUNT_TYPE[0].value:
      switch (subTypeTwo) {
        case GLOBAL_CONSTANTS.COMMERCIAL_SUB_TYPE[0].value:
          result = ["tradeLicense"];
          madatoryList = ["tradeLicense"];
          break;
        case GLOBAL_CONSTANTS.COMMERCIAL_SUB_TYPE[1].value:
          result = ["plotMap", "tradeLicense"];
          madatoryList = ["plotMap", "tradeLicense"];
          break;
        case GLOBAL_CONSTANTS.NON_COMMERCIAL_SUB_TYPE[0].value:
          result = [];
          break;
        case GLOBAL_CONSTANTS.NON_COMMERCIAL_SUB_TYPE[1].value:
          result = []
          break;
        default:
          result = []
          break;
      }
      break;
    case GLOBAL_CONSTANTS.ACCOUNT_TYPE[1].value:
      result = ["sitePlan", "drawing", "contractorTradeLicense"];
      madatoryList = ["sitePlan", "drawing", "contractorTradeLicense"];
      break;
    default:
      result = []
      break;
  }
  if(!isInspection) {
    result.push("signedAgreement");
    madatoryList.push("signedAgreement");
  }

  if (isServiceCompleted) {
    result.push("signedCertificate");
  }

  return {
    pDocumentList: result,
    madatoryList
  };
}

export const getStatusTitle = (status) => {
  switch (status) {
    case GLOBAL_CONSTANTS.SERVICE_TYPE_STATUS['ACTIVE']:
      return Text({tid:"subheaderStatus.active"})
    case GLOBAL_CONSTANTS.SERVICE_TYPE_STATUS['RESCHEDULED']:
      return Text({tid:"subheaderStatus.rescheduled"})
    case GLOBAL_CONSTANTS.SERVICE_TYPE_STATUS['UPCOMING']:
      return Text({tid:"subheaderStatus.upcoming"})
    case GLOBAL_CONSTANTS.SERVICE_TYPE_STATUS['PENDING']:
      return Text({tid:"subheaderStatus.pending"})
    case GLOBAL_CONSTANTS.SERVICE_TYPE_STATUS['REJECTED']:
      return Text({tid:"subheaderStatus.rejected"})
    case GLOBAL_CONSTANTS.SERVICE_TYPE_STATUS['COMPLETED']:
      return Text({tid:"subheaderStatus.completed"})
    default:
      return ""
  }
}

export const getAccountDetaiils = (inspections) => {
  const {
    accountInfo = {}
  } = inspections;

  const {
    accountType,
    subTypeTwo
  } = accountInfo || {};

  switch (accountType) {
    case GLOBAL_CONSTANTS.ACCOUNT_TYPE[0].value:
      switch (subTypeTwo) {
        case GLOBAL_CONSTANTS.COMMERCIAL_SUB_TYPE[0].value:
          return inspections?.businessUserDetails || {}
        case GLOBAL_CONSTANTS.COMMERCIAL_SUB_TYPE[1].value:
          return inspections?.residentialUserDetails || {}
        case GLOBAL_CONSTANTS.NON_COMMERCIAL_SUB_TYPE[0].value:
          return inspections?.publicUserDetails || {}
        case GLOBAL_CONSTANTS.NON_COMMERCIAL_SUB_TYPE[1].value:
          return inspections?.privateUserDetails || {}
        default:
          return {}
      }
    case GLOBAL_CONSTANTS.ACCOUNT_TYPE[1].value:
      return inspections?.underConstructionUserDetails || {}
    default:
      return null
  }
}

export const getAccountDetails = (details) => {
  const {
    accountInfo,
    businessUserDetails = {},
    residentialUserDetails = {},
    publicUserDetails = {},
    privateUserDetails = {},
    underConstructionUserDetails = {},
  } = details || {};
  const { accountType, subTypeTwo } = accountInfo || {};

  switch (accountType) {
    case GLOBAL_CONSTANTS.ACCOUNT_TYPE[0].value:
      switch (subTypeTwo) {
        case GLOBAL_CONSTANTS.COMMERCIAL_SUB_TYPE[0].value:
          return businessUserDetails || {};
        case GLOBAL_CONSTANTS.COMMERCIAL_SUB_TYPE[1].value:
          return residentialUserDetails || {};
        case GLOBAL_CONSTANTS.NON_COMMERCIAL_SUB_TYPE[0].value:
          return publicUserDetails || {};
        case GLOBAL_CONSTANTS.NON_COMMERCIAL_SUB_TYPE[1].value:
          return privateUserDetails || {};
        default:
          return {};
      }
    case GLOBAL_CONSTANTS.ACCOUNT_TYPE[1].value:
      return underConstructionUserDetails || {};
    default:
      return null;
  }
};

export const getPlace = async ({lat, lng}) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCj5RMhQMQBdVtZjggIEHpsYzIKt1ovXlQ`
    );
    return response?.data?.plus_code?.compound_code || null;
  } catch (error) {
    console.log('error: ', error);
    return null;
  }
};

export const latLngFormObject = (latLng) => {
  const latLngArray = latLng ? latLng.split(',') : [];
  const lat = Number(latLngArray[0]);
  const lng = Number(latLngArray[1]);
  return latLng ? { lat, lng } : null;
}

export const latLngFormQueryInput = (latLng = {}) => {
  if((!latLng?.lat || !latLng?.lng) && ( latLng?.lat !== 0 && latLng?.lng !== 0)) {
    return ""
  }
  return `${latLng?.lat},${latLng?.lng}`;
}

export const customParseFloat = (num) => {
  return !isNaN(num) ? parseFloat(num) : null
}

export const getAccountTypeLn = (accountType) => {
  let accountTypeLn = accountType;
  if (accountType === ACCOUNT_TYPE_VALUE.underConstruction) {
    accountTypeLn = Text({ tid: "selectConstants.underConstruction" });
  } else if (accountType === ACCOUNT_TYPE_VALUE.existingEstablishment) {
    accountTypeLn =Text({ tid: "selectConstants.existingEstablishment" });
  }
  return accountTypeLn;
};


export const getAccountSubTypeOneLn = (subTypeOne) => {
  let subTypeOneLn = subTypeOne;
  if (subTypeOne === ACCOUNT_SUB_TYPE.commercial) {
    subTypeOneLn = Text({ tid: "selectConstants.commercial" });
  } else if (subTypeOne === ACCOUNT_SUB_TYPE.nonCommercial) {
    subTypeOneLn = Text({ tid: "selectConstants.noncommercial" });
  }
  return subTypeOneLn;
};

export const getAccountSubTypeTwoLn = (subTypeTwo) => {
  let subTypeTwoLn = subTypeTwo;
  switch(subTypeTwo) {
    case ACCOUNT_SUB_TYPE_TWO.public:
      subTypeTwoLn = Text({ tid: "selectConstants.public" });
      break;
    case ACCOUNT_SUB_TYPE_TWO.private:
      subTypeTwoLn = Text({ tid: "selectConstants.private" });
      break;
    case ACCOUNT_SUB_TYPE_TWO.business:
      subTypeTwoLn = Text({ tid: "selectConstants.business" });
      break;
    case ACCOUNT_SUB_TYPE_TWO.residential:
      subTypeTwoLn = Text({ tid: "selectConstants.residential" });
      break;
    default:
      subTypeTwoLn = subTypeTwo;
      break;
  }
  return subTypeTwoLn;
};

export const getBussinessNameLn = (row) => {
  let bussinessName = row?.bussinessName
  const language = userLanguage.get() || "english";
  if (language == "arabic") {
    bussinessName = row?.bussinessNameArb
  }
  return bussinessName
}

export const getAccountOwnerNameLn = (row) => {
  let ownerName = row?.name
  const language = userLanguage.get() || "english";
  if (language == "arabic") {
    ownerName = row?.nameArb
  }
  return ownerName
}

export const getReportOwnerNameLn = (row) => {
  let ownerName = row?.ownerName
  const language = userLanguage.get() || "english";
  if (language == "arabic") {
    ownerName = row?.ownerNameArb
  }
  return ownerName
}

export const getServiceEstablishmentNameLn = (row) => {
  let establishmentNameArb = row?.establishmentName
  const language = userLanguage.get() || "english";
  if (language == "arabic") {
    establishmentNameArb = row?.establishmentNameArb
  }
  return establishmentNameArb
}

export const getEstablishmentTypeLn = (row) => {
  let establishmentType = row?.name
  const language = userLanguage.get() || "english";
  if (language == "arabic") {
    establishmentType = row?.nameArb
  }
  return establishmentType
}

export const getEmployeeNameLn = (row) => {
  let employeeName = row?.name
  const language = userLanguage.get() || "english";
  if (language == "arabic") {
    employeeName = row?.nameArb
  }
  return employeeName
}

export const getEmployeeRoleLn = (role) => {
  let roleName = "";
  switch(role) {
    case USER_ROLES.ADMIN:
      roleName = Text({ tid: "selectConstants.admin" });
      break;
    case USER_ROLES.MANGER:
      roleName = Text({ tid: "selectConstants.Manager" });
      break;
    case USER_ROLES.CSD:
      roleName = Text({ tid: "selectConstants.csd" });
      break;
    case USER_ROLES.KIOSK_EXECUTIVE:
      roleName = Text({ tid: "selectConstants.kioskExecutive" });
      break;
    case USER_ROLES.FIELD_ENGINEER:
      roleName = Text({ tid: "selectConstants.fieldEngineer" });
      break;
    case USER_ROLES.GOVT_EMPLOYEES:
      roleName = Text({ tid: "selectConstants.govermentEmployee" });
      break;
    case USER_ROLES.OTHERS:
      roleName = Text({ tid: "selectConstants.others" });
      break;
    default:
      roleName = "";
      break;
  }
  return roleName;
};

export const getEmirateLable = (emirate) => {
  const emirateList = [...Translate(EMIRATES_LIST, EMIRATES_LIST_ARB)];
  const { label: emirateLabel = "" } =
    emirateList.find(({ value }) => value === emirate) || {};
  return emirateLabel;
};
