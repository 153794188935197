import { createSlice } from '@reduxjs/toolkit'
import callAPI from 'utils/services'
import { history, notification } from 'utils/helper'
import API_ENDPOINT from 'constants/apiConstants'
import GLOBAL_CONSTANTS from 'constants';
import { Text } from 'languages'
import { isToday } from 'date-fns';

const initialState = {
  list: [],
  totalCount: 0,
  inspections: {},
  inspectionsLoader: false,
  inspectionsDelLoader: '',
  inspectionsApproveLoader: false,
  sheduleState: '',
  documentUploadCount: 0,
  aggrimentGenerated: '',
  inspectionTracking: {},
  inspectionTrackingLoader: false,
  inspectionServiceLoader: false,
  recordVisit:{},
  recordVisitLoader:false,
  createdServiceList:[]
}

export const inspectionsSlice = createSlice({
  name: 'inspections',
  initialState,
  reducers: {
    inspectionsListSuccess: (state, action) => {
      return {
        ...state,
        list: action?.payload?.rows || [],
        totalCount: action?.payload?.count || 0
      }
    },
    inspectionsListFailure: (state) => {
      return {
        ...state,
        list: []
      }
    },
    inspectionsLoaderToggle: (state, action) => {
      return {
        ...state,
        inspectionsLoader: action?.payload
      }
    },
    inspectionServiceCreateLoaderToggle: (state, action) => {
      return {
        ...state,
        inspectionServiceLoader: action?.payload
      }
    },
    inspectionTrackingLoaderToggle: (state, action) => {
      return {
        ...state,
        inspectionTrackingLoader: action?.payload
      }
    },
    inspectionsApproveLoaderToggle: (state, action) => {
      return {
        ...state,
        inspectionsApproveLoader: action?.payload
      }
    },
    inspectionsDelLoaderToggle: (state, action) => {
      return {
        ...state,
        inspectionsDelLoader: action?.payload
      }
    },
    sheduleStateToggle: (state, action) => {
      return {
        ...state,
        sheduleState: action?.payload
      }
    },
    inspectionsCreate: (state) => {
      return {
        ...state,
        inspections: {},
      }
    },
    inspectionsDetailsSuccess: (state, action) => {
      return {
        ...state,
        inspections: action?.payload
      }
    },
    inspectionsDetailsFailure: (state) => {
      return {
        ...state,
        inspections: {}
      }
    },
    inspectionTrackingDetailsSuccess: (state, action) => {
      return {
        ...state,
        inspectionTracking: action?.payload
      }
    },
    inspectionTrackingDetailsFailure: (state) => {
      return {
        ...state,
        inspectionTracking: {}
      }
    },
    documentUploadLoader: (state, action) => {
      return {
        ...state,
        documentUploadCount: state.documentUploadCount + action?.payload
      }
    },
    inspectionsUpdateDetails: (state, action) => {
      return {
        ...state,
        inspections: action?.payload
      }
    },
    setAggrimentGenerated: (state, action) => {
      return {
        ...state,
        aggrimentGenerated: action?.payload
      }
    },
    inspectionRecordVisitSuccess: (state, action) => {
      return {
        ...state,
        recordVisit: action?.payload,
      };
    },
    inspectionRecordVisitReset: (state) => {
      return {
        ...state,
        recordVisit: {},
      };
    },
    createServiceResponse: (state, { payload }) => {
      const { aggrimentGenerated = "", createdServiceList = [] } = payload;
      state.aggrimentGenerated = aggrimentGenerated || "",
      state.createdServiceList = createdServiceList || [];
    },
    inspectionRecordVisitLoader: (state, { payload }) => {
      state.recordVisitLoader = payload
    },
    resetInspectionState: () => initialState
  }
})

export const {
  inspectionsLoaderToggle,
  inspectionsCreate,
  inspectionsListSuccess,
  inspectionsListFailure,
  inspectionsDetailsFailure,
  inspectionsDetailsSuccess,
  inspectionsDelLoaderToggle,
  inspectionsApproveLoaderToggle,
  sheduleStateToggle,
  documentUploadLoader,
  inspectionsUpdateDetails,
  setAggrimentGenerated,
  inspectionTrackingLoaderToggle,
  inspectionTrackingDetailsSuccess,
  inspectionTrackingDetailsFailure,
  inspectionServiceCreateLoaderToggle,
  resetInspectionState,
  inspectionRecordVisitLoader,
  inspectionRecordVisitSuccess,
  inspectionRecordVisitReset,
  createServiceResponse
} = inspectionsSlice.actions

export function inspectionsDetailsAction(payload) {
  return async (dispatch) => {

    dispatch(inspectionsLoaderToggle(true))
    const response = await callAPI({
      url: API_ENDPOINT.V1.INSPECTION+'/'+payload,
      method: 'GET'
    });

    if(response.status === 200) {
      dispatch(inspectionsDetailsSuccess(response?.data || {}))
    } else {
      dispatch(inspectionsDetailsFailure())
    }
    dispatch(inspectionsLoaderToggle(false))
  }
}

export function getInspectionRecordVisit(payload) {
  return async (dispatch) => {

    dispatch(inspectionRecordVisitLoader(true))
    const response = await callAPI({
      url: API_ENDPOINT.V1.INSPECTION_VISIT_RECORD+'/'+payload,
      method: 'GET'
    });

    if(response.status === 200) {
      dispatch(inspectionRecordVisitSuccess(response?.data || {}))
    } else {
      dispatch(inspectionRecordVisitReset())
    }
    dispatch(inspectionRecordVisitLoader(false))
  }
}

export function inspectionTrackingDetailsAction(payload) {
  return async (dispatch) => {

    dispatch(inspectionTrackingLoaderToggle(true))
    const response = await callAPI({
      url: API_ENDPOINT.V1.INSPECTION_TRACKING+'/'+payload,
      method: 'GET'
    });

    if(response.status === 200) {
      dispatch(inspectionTrackingDetailsSuccess(response?.data || {}))
    } else {
      dispatch(inspectionTrackingDetailsFailure())
    }
    dispatch(inspectionTrackingLoaderToggle(false))
  }
}

export function inspectionsUpdateAction({ id, params }) {
  return async (dispatch) => {
    const data = {
      ...params,
      status: GLOBAL_CONSTANTS.SERVICE_TYPE_STATUS["PENDING"],
      inspectionStatus: GLOBAL_CONSTANTS.SERVICES_STATUS["PENDING_FOR_APPROVAL"],
    }
    dispatch(inspectionsLoaderToggle(true))
    const response = await callAPI({
      url: API_ENDPOINT.V1.INSPECTION+'/'+id,
      method: 'PUT',
      data
    });

    if (response.status === 200) {
      dispatch(inspectionsCreate())
      notification({
        type: 'success',
        message: response?.message || <Text tid={"messages.inspectionUpdatedSuccessfully"}/>
      })
      history.navigate('/inspections/'+id+'/view')
    } else {
      notification({
        type: 'error',
        message: response?.message
      })
    }
    dispatch(inspectionsLoaderToggle(false))
  }
}

export function inspectionsGetListAction(payload) {
  return async (dispatch) => {
    const params = {
      pageNo: 1,
      pageSize: 10,
      ...payload
    }
    dispatch(inspectionsLoaderToggle(true))
    const response = await callAPI({
      url:API_ENDPOINT.V1.INSPECTION_LIST,
      method: 'POST',
      data: params
    });

    if(response.status === 200) {
      dispatch(inspectionsListSuccess(response?.data || {}))
    } else {
      dispatch(inspectionsListFailure())
    }
    dispatch(inspectionsLoaderToggle(false))
  }
}

export function inspectionsStatusAction({id, params, type}) {
  return async (dispatch, getState) => {
    dispatch(inspectionsApproveLoaderToggle('LOADING'))
    const response = await callAPI({
      url: API_ENDPOINT.V1.INSPECTION_STATUS+'/'+id,
      method: 'PUT',
      data: params
    });

    if(response.status === 200) {
      notification({
        type: 'success',
        message: response?.message
      })
      if(type === 'REJECT') {
        history.navigate('/inspections?status=4')
        dispatch(inspectionsApproveLoaderToggle(''))
      } else if(type === 'RESUBMIT') {
        history.navigate('/inspections?status=1')
        dispatch(inspectionsApproveLoaderToggle(''))
      } else if(type === 'APPROVE') {
        const { inspections : { inspections } } = getState();
        dispatch(inspectionsUpdateDetails({
          ...inspections,
          ...params
        }))
        dispatch(inspectionsApproveLoaderToggle('SUCCESS'))
      } else {
        dispatch(inspectionsApproveLoaderToggle('SUCCESS'))
      }
    } else {
      dispatch(inspectionsApproveLoaderToggle(''))
      notification({
        type: 'error',
        message: response?.message || <Text tid={"messages.pleaseTryLater"}/>
      })
    }
  }
}

export function inspectionsSheduleAction(params) {
  return async (dispatch, getState) => {
    dispatch(inspectionsApproveLoaderToggle('LOADING'))
    const response = await callAPI({
      url: API_ENDPOINT.V1.INSPECTION_SHEDULE,
      method: 'POST',
      data: params
    });

    if(response.status === 200) {
      notification({
        type: 'success',
        message: response?.message
      })
      const status = isToday(new Date(params?.prefferedDateofService))
        ? GLOBAL_CONSTANTS.SERVICE_TYPE_STATUS.ACTIVE
        : GLOBAL_CONSTANTS.SERVICE_TYPE_STATUS.UPCOMING;
      history.navigate(`/inspections?status=${status}`)
      dispatch(inspectionsApproveLoaderToggle(''))
    } else {
      dispatch(inspectionsApproveLoaderToggle(''))
      notification({
        type: 'error',
        message: response?.message || <Text tid={"messages.pleaseTryLater"}/>
      })
    }
  }
}

export function inspectionsRecordVisitAction(inspectionId, params) {
  return async (dispatch) => {
    dispatch(inspectionsApproveLoaderToggle("LOADING"));
    const response = await callAPI({
      url: `${API_ENDPOINT.V1.INSPECTION_VISIT_RECORD}/${inspectionId}`,
      method: "PUT",
      data: params,
    });

    if (response.status === 200) {
      notification({
        type: "success",
        message: response?.message,
      });

      const { INSPECTION_TYPE_STATUS, INSPECTION_STATUS } = GLOBAL_CONSTANTS;
      history.navigate(
        "/inspections?status=" +
          (params?.inspectionStatus === INSPECTION_STATUS?.INSPECTION_SUCCESSFUL
            ? INSPECTION_TYPE_STATUS?.COMPLETED
            : INSPECTION_TYPE_STATUS?.ACTIVE)
      );
      dispatch(inspectionsApproveLoaderToggle(""));
    } else {
      dispatch(inspectionsApproveLoaderToggle(""));
      notification({
        type: "error",
        message: response?.message || <Text tid={"messages.pleaseTryLater"} />,
      });
    }
  };
}

export function inspectionsDeleteAction(id, data) {
  return async (dispatch) => {

    dispatch(inspectionsDelLoaderToggle('LOADING'))
    const response = await callAPI({
      url: API_ENDPOINT.V1.INSPECTION_DELETE+'/'+id,
      method: 'DELETE',
      data
    });

    if(response.status === 200) {
      notification({
        type: 'success',
        message: <Text tid={"messages.inspectionSuspendedSuccessfully"}/>
      })
      dispatch(inspectionsDelLoaderToggle('SUCCESS'))
      history.navigate('/inspections')
    } else {
      notification({
        type: 'error',
        message: response?.message || <Text tid={"messages.pleaseTryLater"}/>
      })
      dispatch(inspectionsDelLoaderToggle(''))
    }
  }
}

export function inspectionsCreateServiceAction(payload, aggrimentGenerated) {
  return async (dispatch) => {
    dispatch(inspectionServiceCreateLoaderToggle("CREATE_SERVICE"));
    const generatePdf = !aggrimentGenerated ? true : false;
    const { status, message, data } = await callAPI({
      url: API_ENDPOINT.V1.INSPECTION_CREATE_SERVICE,
      method: "POST",
      data: {
        ...payload,
        generatePdf,
      },
    });

    if (status === 200) {
      if (aggrimentGenerated) {
        notification({
          type: "success",
          message,
        });
        history.navigate("/services?status=1");
        dispatch(inspectionServiceCreateLoaderToggle(""));
      } else {
        const { createdServiceList = [], file = {} } = data || {};
        dispatch(
          createServiceResponse({
            aggrimentGenerated: file?.path || "",
            createdServiceList,
          })
        );
        notification({
          type: "success",
          message: <Text tid={"messages.serviceAgreementGenerated"} />,
        });
        dispatch(inspectionServiceCreateLoaderToggle(""));
      }
    } else {
      dispatch(inspectionServiceCreateLoaderToggle(""));
      notification({
        type: "error",
        message: message || <Text tid={"messages.pleaseTryLater"} />,
      });
    }
  };
}

export default inspectionsSlice.reducer
