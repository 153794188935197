import { createContext, useContext, useState } from 'react';
import _ from 'lodash';
import GLOBAL_CONSTANTS from 'constants';
import { userLanguage } from 'utils/helper';

import eng from './eng.json';
import arb from './arb.json';

export const dictionaryList = {
  [GLOBAL_CONSTANTS.LANGUAGES[0].value]: eng,
  [GLOBAL_CONSTANTS.LANGUAGES[1].value]: arb
};

export const LanguageContext = createContext();

export function LanguageProvider({ children }) {
  const [language, setUserLanguage] = useState(userLanguage.get() || GLOBAL_CONSTANTS.LANGUAGES[0].value);
  const provider = {
    language,
    dictionary: dictionaryList[language],
    userLanguageChange: selected => {
      setUserLanguage(selected);
    }
  };
  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

export function Text({ tid }) {
  const language = userLanguage.get() || "english";
  const dictionary= dictionaryList[language];
  return _.get(dictionary, tid) || tid;
};


export function getText({tid}) {
  const language = userLanguage.get() || "english";
  const dictionary= dictionaryList[language];
  return _.get(dictionary, tid) || tid;
}

export function Translate(eng, arb) {
  const language  = userLanguage.get() || GLOBAL_CONSTANTS.LANGUAGES[0].value;
  return language === GLOBAL_CONSTANTS.LANGUAGES[1].value ? arb : eng;
};
