import { createSlice } from "@reduxjs/toolkit";
import GLOBAL_CONSTANTS from "constants";
const { MENU: SIDEMENU = []} = GLOBAL_CONSTANTS || {};

export const sidebar = createSlice({
  name: "sidebar",
  initialState: {
    sidebarShow: true,
    menuData: SIDEMENU,
  },
  reducers: {
    set: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return {
        ...state,
        sidebarShow: action.payload
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { set } = sidebar.actions

export default sidebar.reducer