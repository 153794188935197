import { createSlice } from '@reduxjs/toolkit'

export const header = createSlice({
  name: 'header',
  initialState: {
    inormativePoupOpen: false,
    title: '',
    description: '',
    redirectUrl: ''
  },
  reducers: {
    inormativePoupOpenToggle: (state, action) => {
      return {
        ...state,
        inormativePoupOpen: action?.payload?.open,
        title: action?.payload?.title,
        description: action?.payload?.description,
        redirectUrl: action?.payload?.redirectUrl
      }
    }
  }
})

export const { inormativePoupOpenToggle } = header.actions

export default header.reducer