import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import Loader from "components/Loader";
import { profileAction } from "store/slices/authentication";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoggedIn, userProfile, loginLoader } = useSelector(
    (state) => state.authentication
  );

  useEffect(() => {
    if (isLoggedIn && !userProfile && !loginLoader) {
      dispatch(profileAction());
    }
  }, [userProfile, loginLoader, isLoggedIn]);

  const [navigationState] = useState({ from: location });

  if (!isLoggedIn) {
    return <Navigate to="/login" state={navigationState} replace />;
  } else if (!userProfile) {
    return <Loader />;
  } else {
    return children;
  }
};

export default PrivateRoute;
