import { createSlice } from '@reduxjs/toolkit'
import callAPI from 'utils/services'
import API_ENDPOINT from 'constants/apiConstants'

export const dashboard = createSlice({
  name: 'dashboard',
  initialState: {
    dashboardData: {},
    dashboardLoader: false,
    filterParams: {}
  },
  reducers: {
    dashboardListSuccess: (state, action) => {
      return {
        ...state,
        dashboardData: action?.payload || {},
        totalCount: action?.payload?.count || 0
      }
    },
    dashboardListFailure: (state) => {
      return {
        ...state,
        dashboardData: {}
      }
    },
    dashboardLoaderToggle: (state, action) => {
      return {
        ...state,
        dashboardLoader: action?.payload
      }
    },
    setDashboardFilterParams: (state, action) => {
      return {
        ...state,
        filterParams: action?.payload,
      };
    },
    resetDashboardDateFilter: (state, _action) => {
      state.filterParams.toDate = null;
      state.filterParams.fromDate = null;
    },
    resetDashboardAccountTypeFilter: (state, _action) => {
      state.filterParams.accountType = null;
    },
    resetDashboardFilter: (state, _action) => {
      state.filterParams = {};
    },
  }
})

export const {
  dashboardListSuccess,
  dashboardListFailure,
  dashboardLoaderToggle,
  setDashboardFilterParams,
  resetDashboardDateFilter,
  resetDashboardAccountTypeFilter,
  resetDashboardFilter
} = dashboard.actions

export function dashboardGetListAction(payload) {
  return async (dispatch) => {
    const params = {
      accountType: null,
      fromDate: null,
      toDate: null,
      ...payload
    }
    dispatch(dashboardLoaderToggle(true))
    const response = await callAPI({
      url: API_ENDPOINT.V1.DASHBOARD,
      method: 'GET',
      params
    });
    if(response.status === 200) {
      dispatch(setDashboardFilterParams(payload))
      dispatch(dashboardListSuccess(response?.data || {}))
    } else {
      dispatch(dashboardListFailure())
    }
    dispatch(dashboardLoaderToggle(false))
  }
}

export default dashboard.reducer