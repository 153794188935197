import { createSlice } from '@reduxjs/toolkit'
import callAPI from 'utils/services'
import { history, notification } from 'utils/helper'
import API_ENDPOINT from 'constants/apiConstants'
import GLOBAL_CONSTANTS from 'constants';
import { Text } from 'languages'
import { isToday } from 'date-fns';

const initialState = {
  list: [],
  totalCount: 0,
  services: {},
  servicesLoader: false,
  servicesDelLoader: '',
  servicesApproveLoader: false,
  sheduleState: '',
  documentUploadCount: 0,
  aggrimentGenerated: '',
  serviceTracking: {},
  serviceTrackingLoader: false,
  certificateGenerated: '',
  recordVisit:{},
  recordVisitLoader:false,
  establishmentDetails: {},
  createdServiceList: []
}

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    servicesListSuccess: (state, action) => {
      return {
        ...state,
        list: action?.payload?.rows || [],
        totalCount: action?.payload?.count || 0,
      };
    },
    servicesListFailure: (state) => {
      return {
        ...state,
        list: [],
      };
    },
    servicesLoaderToggle: (state, action) => {
      return {
        ...state,
        servicesLoader: action?.payload,
      };
    },
    serviceTrackingLoaderToggle: (state, action) => {
      return {
        ...state,
        serviceTrackingLoader: action?.payload,
      };
    },
    servicesApproveLoaderToggle: (state, action) => {
      return {
        ...state,
        servicesApproveLoader: action?.payload,
      };
    },
    servicesDelLoaderToggle: (state, action) => {
      return {
        ...state,
        servicesDelLoader: action?.payload,
      };
    },
    serviceTrackingDetailsSuccess: (state, action) => {
      return {
        ...state,
        serviceTracking: action?.payload,
      };
    },
    serviceTrackingDetailsFailure: (state) => {
      return {
        ...state,
        serviceTracking: {},
      };
    },
    sheduleStateToggle: (state, action) => {
      return {
        ...state,
        sheduleState: action?.payload,
      };
    },
    servicesCreate: (state) => {
      return {
        ...state,
        services: {},
      };
    },
    servicesDetailsSuccess: (state, action) => {
      return {
        ...state,
        services: action?.payload,
      };
    },
    servicesDetailsFailure: (state) => {
      return {
        ...state,
        services: {},
      };
    },
    documentUploadLoader: (state, action) => {
      return {
        ...state,
        documentUploadCount: state.documentUploadCount + action?.payload,
      };
    },
    servicesUpdateDetails: (state, action) => {
      console.log(action?.payload);
      return {
        ...state,
        services: action?.payload,
      };
    },
    setAggrimentGenerated: (state, { payload }) => {
      state.aggrimentGenerated = payload;
    },
    setCertificateGenerated: (state, action) => {
      return {
        ...state,
        certificateGenerated: action?.payload,
      };
    },
    servicesRecordVisitSuccess: (state, action) => {
      return {
        ...state,
        recordVisit: action?.payload,
      };
    },
    servicesRecordVisitReset: (state) => {
      return {
        ...state,
        recordVisit: {},
      };
    },
    servicesRecordVisitLoader: (state, { payload }) => {
      state.recordVisitLoader = payload;
    },
    saveCreateServiceResponse: (state, { payload }) => {
      const { aggrimentGenerated = "", createdServiceList = [] } = payload;
      state.aggrimentGenerated = aggrimentGenerated || "",
      state.createdServiceList = createdServiceList || [];
    },
    resetServiceState: () => initialState,
  },
});

export const {
  servicesLoaderToggle,
  servicesCreate,
  servicesListSuccess,
  servicesListFailure,
  servicesDetailsFailure,
  servicesDetailsSuccess,
  servicesDelLoaderToggle,
  servicesApproveLoaderToggle,
  sheduleStateToggle,
  documentUploadLoader,
  servicesUpdateDetails,
  setAggrimentGenerated,
  serviceTrackingLoaderToggle,
  serviceTrackingDetailsSuccess,
  serviceTrackingDetailsFailure,
  resetServiceState,
  setCertificateGenerated,
  servicesRecordVisitSuccess,
  servicesRecordVisitReset,
  servicesRecordVisitLoader,
  saveCreateServiceResponse
} = servicesSlice.actions

export function servicesDetailsAction(payload) {
  return async (dispatch) => {

    dispatch(servicesLoaderToggle(true))
    const response = await callAPI({
      url: API_ENDPOINT.V1.SERVICE+'/'+payload,
      method: 'GET'
    });

    if(response.status === 200) {
      dispatch(servicesDetailsSuccess(response?.data || {}))
    } else {
      dispatch(servicesDetailsFailure())
    }
    dispatch(servicesLoaderToggle(false))
  }
}

export function getRecordVist(payload) {
  return async (dispatch) => {

    dispatch(servicesRecordVisitLoader(true))
    const response = await callAPI({
      url: API_ENDPOINT.V1.SERVICE_VISIT_RECORD+'/'+payload,
      method: 'GET'
    });

    if(response.status === 200) {
      dispatch(servicesRecordVisitSuccess(response?.data || {}))
    } else {
      dispatch(servicesRecordVisitReset())
    }
    dispatch(servicesRecordVisitLoader(false))
  }
}

export function serviceTrackingDetailsAction(payload) {
  return async (dispatch) => {

    dispatch(serviceTrackingLoaderToggle(true))
    const response = await callAPI({
      url: API_ENDPOINT.V1.SERVICE_TRACKING+'/'+payload,
      method: 'GET'
    });

    if(response.status === 200) {
      dispatch(serviceTrackingDetailsSuccess(response?.data || {}))
    } else {
      dispatch(serviceTrackingDetailsFailure())
    }
    dispatch(serviceTrackingLoaderToggle(false))
  }
}

export function servicesCreateAction(payload, aggrimentGenerated) {
  return async (dispatch) => {
    const { inspectionRequired: isInspection } = payload || {};
    const generatePdf = !aggrimentGenerated && !isInspection ? true : false;
    const params = {
      ...payload,
      generatePdf,
    };
    dispatch(servicesLoaderToggle(true));
    const { status, message, data } = await callAPI({
      url: API_ENDPOINT.V1.SERVICE_CREATE,
      method: "POST",
      data: params,
    });

    if (status === 201) {
      if (aggrimentGenerated || isInspection) {
        dispatch(servicesCreate());
        dispatch(setAggrimentGenerated(""));
        notification({
          type: "success",
          message:
            <Text tid={"messages.serviceCreatedSuccessfully"} /> || message,
        });
        if (isInspection) {
          history.navigate("/inspections?status=1");
        } else {
          history.navigate("/services?status=1");
        }
      } else {
        const { createdServiceList = [], file = {} } = data || {};
        dispatch(
          saveCreateServiceResponse({
            aggrimentGenerated: file?.path || "",
            createdServiceList,
          })
        );

        notification({
          type: "success",
          message: <Text tid={"messages.serviceAgreementGenerated"} />,
        });
      }
    } else {
      notification({
        type: "error",
        message: message,
      });
    }
    dispatch(servicesLoaderToggle(false));
  };
}

export function servicesUpdateAction({ id, params }) {
  return async (dispatch) => {
    const data = {
      ...params,
      status: GLOBAL_CONSTANTS.SERVICE_TYPE_STATUS["PENDING"],
      serviceStatus: GLOBAL_CONSTANTS.SERVICES_STATUS["PENDING_FOR_APPROVAL"],
    }
    dispatch(servicesLoaderToggle(true))
    const response = await callAPI({
      url: API_ENDPOINT.V1.SERVICE+'/'+id,
      method: 'PUT',
      data
    });

    if (response.status === 200) {
      dispatch(servicesCreate())
      notification({
        type: 'success',
        message: response?.message || <Text tid={"messages.serviceUpdatedSuccessfully"}/>
      })
      history.navigate('/services/'+id+'/view')
    } else {
      notification({
        type: 'error',
        message: response?.message
      })
    }
    dispatch(servicesLoaderToggle(false))
  }
}

export function updateSignedAgreement(serviceId, data) {
  return async (dispatch) => {
    dispatch(servicesLoaderToggle(true));
    const response = await callAPI({
      url: API_ENDPOINT.V1.SERVICE + "/" + serviceId,
      method: "PUT",
      data,
    });

    if (response.status === 200) {
      dispatch(servicesCreate());
      notification({
        type: "success",
        message: response?.message || (
          <Text tid={"messages.serviceUpdatedSuccessfully"} />
        ),
      });
      dispatch(servicesDetailsAction(serviceId));
    } else {
      notification({
        type: "error",
        message: response?.message,
      });
    }
    dispatch(servicesLoaderToggle(false));
  };
}

export function servicesGetListAction(payload) {
  return async (dispatch) => {
    const params = {
      pageNo: 1,
      pageSize: 10,
      ...payload
    }
    dispatch(servicesLoaderToggle(true))
    const response = await callAPI({
      url:API_ENDPOINT.V1.SERVICE_LIST,
      method: 'POST',
      data: params
    });

    if(response.status === 200) {
      dispatch(servicesListSuccess(response?.data || {}))
    } else {
      dispatch(servicesListFailure())
    }
    dispatch(servicesLoaderToggle(false))
  }
}

export function servicesStatusAction({id, params, type}) {
  return async (dispatch, getState) => {
    dispatch(servicesApproveLoaderToggle('LOADING'))
    const response = await callAPI({
      url: API_ENDPOINT.V1.SERVICE_STATUS+'/'+id,
      method: 'PUT',
      data: params
    });

    if(response.status === 200) {
      notification({
        type: 'success',
        message: response?.message
      })
      if(type === 'REJECT') {
        history.navigate('/services?status=4')
        dispatch(servicesApproveLoaderToggle(''))
      } else if(type === 'RESUBMIT') {
        history.navigate('/services?status=1')
        dispatch(servicesApproveLoaderToggle(''))
      } else if(type === 'APPROVE') {
        const { services : { services } } = getState();
        dispatch(servicesUpdateDetails({
          ...services,
          ...params
        }))
        dispatch(servicesApproveLoaderToggle('SUCCESS'))
      } else {
        dispatch(servicesApproveLoaderToggle('SUCCESS'))
      }
    } else {
      dispatch(servicesApproveLoaderToggle(''))
      notification({
        type: 'error',
        message: response?.message || <Text tid={"messages.pleaseTryLater"}/>
      })
    }
  }
}

export function servicesSheduleAction(params) {
  return async (dispatch) => {
    dispatch(servicesApproveLoaderToggle('LOADING'))
    const response = await callAPI({
      url: API_ENDPOINT.V1.SERVICE_SHEDULE,
      method: 'PUT',
      data: params
    });

    if(response.status === 200) {
      notification({
        type: 'success',
        message: response?.message
      })
      const status = isToday(new Date(params?.prefferedDateofService))
        ? GLOBAL_CONSTANTS.SERVICE_TYPE_STATUS.ACTIVE
        : GLOBAL_CONSTANTS.SERVICE_TYPE_STATUS.UPCOMING;
      history.navigate(`/services?status=${status}`)
      dispatch(servicesApproveLoaderToggle(''))
    } else {
      dispatch(servicesApproveLoaderToggle(''))
      notification({
        type: 'error',
        message: response?.message || <Text tid={"messages.pleaseTryLater"}/>
      })
    }
  }
}

export function servicesRecordVisitAction(params) {
  return async (dispatch) => {
    dispatch(servicesApproveLoaderToggle("LOADING"));
    const response = await callAPI({
      url: API_ENDPOINT.V1.SERVICE_VISIT_RECORD,
      method: "POST",
      data: params,
    });

    if (response.status === 200) {
      notification({
        type: "success",
        message: response?.message,
      });
      const { serviceStatus, status } = response?.data || {};
      const { SERVICES_STATUS } = GLOBAL_CONSTANTS;

      if (serviceStatus === SERVICES_STATUS.REPORT_GENERATED) {
        dispatch(getRecordVist(params.id));
      } else {
        history.navigate(`/services?status=${status}`);
      }
      dispatch(servicesApproveLoaderToggle(""));
    } else {
      dispatch(servicesApproveLoaderToggle(""));
      notification({
        type: "error",
        message: response?.message || <Text tid={"messages.pleaseTryLater"} />,
      });
    }
  };
}

export function servicesDeleteAction(id,data = {}) {
  return async (dispatch) => {

    dispatch(servicesDelLoaderToggle('LOADING'))
    const response = await callAPI({
      url: API_ENDPOINT.V1.SERVICE_DELETE+'/'+id,
      method: 'DELETE',
      data
    });

    if(response.status === 200) {
      notification({
        type: 'success',
        message:  <Text tid={"messages.serviceSuspendedSuccessfully"}/>
      })
      dispatch(servicesDelLoaderToggle('SUCCESS'))
      history.navigate('/services')
    } else {
      notification({
        type: 'error',
        message: response?.message || <Text tid={"messages.pleaseTryLater"}/>
      })
      dispatch(servicesDelLoaderToggle(''))
    }
  }
}

export default servicesSlice.reducer

